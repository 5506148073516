import React from 'react';
import "../CSS/landingpage.css";
import { Slide, Zoom } from 'react-awesome-reveal';


function LandingPage() {
  return (
    <div className="landingpage">
      <div className='img'>
        <img src="../assets/landing/dubai.jpg" alt="" />
      </div>
      <div className="disc">
        <div className="title">
        <Slide direction="right">
          <h1>
            VENDOMES
          </h1>

        </Slide>
        <Slide direction="left">
          <h2>
            GROUP
          </h2>
        </Slide>
          <Zoom>
            
          <div className="border">
            <h3>
              Connecting Dubai to the World:<br /> Your Global General Trading Partner.
            </h3>
          </div>

          </Zoom>
        </div>
      </div>
    </div>
  )
}

export default LandingPage;
