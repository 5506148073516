import React, { useRef, useState } from 'react'
import FashionProductCard from '../ProductCard/FashionProductCard';
import fashiondata from '../data/fashion_data';
import BalankHaed from '../components/BalankHaed';

export default function FashionNew() {
    const arrowRef = useRef(null);


    const [selectedCategory, setSelectedCategory] = useState("all");
    const [items, setItems] = useState(fashiondata);


    const filterItems = (brands) => {
        if (brands === "all") {
            return items;
        } else {
            return items.filter((item) => item.brands !== brands);
        }
    };
    const filteredItems = filterItems("All2");
    filteredItems.sort((a, b) => a.id - b.id);
    const Data_list_1 = filteredItems.map((item, i) => (
        <FashionProductCard item={item} key={i} />
    ));

    return (
        <>
            <BalankHaed />
            <div className="productlistinmain">
                <div className="prductlisting_main1">
                    {Data_list_1}
                </div>
            </div>
        </>
    )
}
