export const carlist = [
    {
        id: 7001,
        title: "2021 Lamborghini Huracan",
        brands: "Cars",
        types: "Lamborghini",
        type2: "Tops",
        price: "940,900",
        recomended: true,  
        thumbnail: "../assets/product/cars/01/01.webp",
        images: [
            "../assets/product/cars/01/01.webp",
            "../assets/product/cars/01/02.webp",
            "../assets/product/cars/01/03.webp",
            "../assets/product/cars/01/04.webp", 
            "../assets/product/cars/01/05.webp",
            "../assets/product/cars/01/06.webp",
            "../assets/product/cars/01/07.webp",
            "../assets/product/cars/01/08.webp",
            "../assets/product/cars/01/09.webp",
            "../assets/product/cars/01/10.webp",
        ],
        tags: [
            "EVO RWD Spyder 2dr Convertible",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "6,668",
            "Bianco Monocerus",
            "Interior Color Nero Ade/Nero Ade Sportivo Unicolor Leather",
            "Gas",
            "Automated Manual",
            "601 hp",
        ],


    },
 {
        id: 7005,
        title: "2021 Lamborghini Urus",
        brands: "Cars",
        types: "Lamborghini",
        type2: "Tops",
        price: "1,350,000",
        recomended: false,  
        thumbnail: "../assets/product/cars/05/01.webp",
        images: [
            "../assets/product/cars/05/01.webp",
            "../assets/product/cars/05/02.webp",
            "../assets/product/cars/05/03.webp",
            "../assets/product/cars/05/04.jpg", 
            "../assets/product/cars/05/05.webp",
            "../assets/product/cars/05/06.webp",
            "../assets/product/cars/05/07.webp",
            "../assets/product/cars/05/08.webp",
            "../assets/product/cars/05/09.webp",
            "../assets/product/cars/05/10.webp",
        ],
        tags: [
            "4dr SUV AWD ",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "5,292",
            "Balloon White Arancio Borealis Pearl",
            "Nero Ade/Giallo Taurus Sportivo Alcantara",
            "Gas",
            "Automatic",
            "641 hp",
        ],


    },
 {
        id: 7010,
        title: "2021 Lamborghini Urus",
        brands: "Cars",
        types: "Lamborghini",
        type2: "Tops",
        price: "1,279,999",
        recomended: true,  
        thumbnail: "../assets/product/cars/10/01.jpg",
        images: [
            "../assets/product/cars/10/01.jpg",
            "../assets/product/cars/10/02.webp",
            "../assets/product/cars/10/03.webp",
            "../assets/product/cars/10/04.webp", 
            "../assets/product/cars/10/05.webp",
            "../assets/product/cars/10/06.webp",
            "../assets/product/cars/10/07.webp",
            "../assets/product/cars/10/08.webp",
            "../assets/product/cars/10/09.webp",
            "../assets/product/cars/10/10.webp",
        ],
        tags: [
            "4dr SUV AWD",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "7,937",
            "Blu Astraeus Matt",
            " Nero Ade/Arancio Leonis Sportivo Alcantara",
            "Gas",
            "Automatic",
            "641 hp",
        ],


    },
 {
        id: 7015,
        title: "2023 Lamborghini Huracan STO",
        brands: "Cars",
        types: "Lamborghini",
        type2: "Tops",
        price: "1,279,900",
        recomended: true,  
        thumbnail: "../assets/product/cars/15/01.webp",
        images: [
            "../assets/product/cars/15/01.webp",
            "../assets/product/cars/15/02.webp",
            "../assets/product/cars/15/03.webp",
            "../assets/product/cars/15/04.webp", 
            "../assets/product/cars/15/05.webp",
            "../assets/product/cars/15/06.webp",
            "../assets/product/cars/15/07.webp",
            "../assets/product/cars/15/08.webp",
            "../assets/product/cars/15/09.webp",
            "../assets/product/cars/15/10.webp",
        ],
        tags: [
            "2dr Coupe",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "644",
            "644",
            " Nero Cosmus/Arancio Dryope Sportivo Bicolor Alcantara",
            "Gas",
            "Automated Manual",
            "631 hp",
        ],


    },
 {
        id: 7021
        ,
        title: "2023 Lamborghini Urus",
        brands: "Cars",
        types: "Lamborghini",
        type2: "Tops",
        price: "320,495",
        recomended: false,  
        thumbnail: "../assets/product/cars/20/01.webp",
        images: [
            "../assets/product/cars/20/01.webp",
            "../assets/product/cars/20/02.jpg",
            "../assets/product/cars/20/03.webp",
            "../assets/product/cars/20/04.webp", 
            "../assets/product/cars/20/05.webp",
            "../assets/product/cars/20/06.webp",
            "../assets/product/cars/20/07.webp",
            "../assets/product/cars/20/08.webp",
            "../assets/product/cars/20/09.webp",
            "../assets/product/cars/20/10.webp",
        ],
        tags: [
            "Performante 4dr SUV AWD",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "3,317",
            "Bianco Monocerus",
            "Bianco Monocerus",
            "Gas",
            "Automatic",
            "657 hp",
        ],


    },
{
        id: 7002,
        title: "2022 Land Rover Range Rover Sport",
        brands: "Cars",
        types: "Range Rover",
        type2: "Tops",
        price: "560,500",
        recomended: true,  
        thumbnail: "../assets/product/cars/02/01.webp",
        images: [
            "../assets/product/cars/02/01.webp",
            "../assets/product/cars/02/02.webp",
            "../assets/product/cars/02/03.webp",
            "../assets/product/cars/02/04.webp", 
            "../assets/product/cars/02/05.webp",
            "../assets/product/cars/02/06.webp",
            "../assets/product/cars/02/07.webp",
            "../assets/product/cars/02/08.webp",
            "../assets/product/cars/02/09.webp",
            "../assets/product/cars/02/10.webp",
        ],
        tags: [
            "Performante 4dr SUV AWD",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "6,576",
            "Santorini Black Metallic",
            "Santorini Black Metallic",
            "Gas",
            "Automatic",
            "575 hp",
        ],


    },
{
        id: 7006,
        title: "2023 Land Rover Defender",
        brands: "Cars",
        types: "Range Rover",
        type2: "Tops",
        price: "390,500",
        recomended: false,  
        thumbnail: "../assets/product/cars/06/01.jpg",
        images: [
            "../assets/product/cars/06/01.jpg",
            "../assets/product/cars/06/02.jpg",
            "../assets/product/cars/06/02.jpg",
            "../assets/product/cars/06/04.webp", 
            "../assets/product/cars/06/05.webp",
            "../assets/product/cars/06/06.webp",
            "../assets/product/cars/06/07.webp",
            "../assets/product/cars/06/08.webp",
            "../assets/product/cars/06/09.webp",
            "../assets/product/cars/06/10.webp",
        ],
        tags: [
            "130 P400 X 4dr SUV AWD",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "3,262",
            "Santorini Black Metallic",
            "Ebony Premium Leather/Cloth",
            "Mild Hybrid",
            "Automatic",
            "395 hp",
        ],


    },
{
        id: 7011,
        title: "2023 Land Rover Defender",
        brands: "Cars",
        types: "Range Rover",
        type2: "Tops",
        price: "385,000",
        recomended: true,  
        thumbnail: "../assets/product/cars/11/01.webp",
        images: [
            "../assets/product/cars/11/01.webp",
            "../assets/product/cars/11/02.webp",
            "../assets/product/cars/11/03.webp",
            "../assets/product/cars/11/04.webp", 
            "../assets/product/cars/11/05.webp",
            "../assets/product/cars/11/06.webp",
            "../assets/product/cars/11/07.webp",
            "../assets/product/cars/11/08.webp",
            "../assets/product/cars/11/09.webp",
            "../assets/product/cars/11/10.webp",
        ],
        tags: [
            "110 P400 X-Dynamic SE 4dr SUV AWD ",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "19,969",
            "Silicon Silver Premium Metallic",
            " Ebony Premium Cloth/Premium Leather",
            "Mild Hybrid",
            "Automatic",
            "395 hp",
        ],


    },
{
        id: 7016,
        title: "2023 Land Rover Range Rover Sport",
        brands: "Cars",
        types: "Range Rover",
        type2: "Tops",
        price: "502,00",
        recomended: false,  
        thumbnail: "../assets/product/cars/16/01.jpg",
        images: [
            "../assets/product/cars/16/01.jpg",
            "../assets/product/cars/16/02.jpg",
            "../assets/product/cars/16/03.jpg",
            "../assets/product/cars/16/04.jpg", 
            "../assets/product/cars/16/05.jpg",
            "../assets/product/cars/16/06.jpg",
            "../assets/product/cars/16/07.jpg",
            "../assets/product/cars/16/08.jpg",
            "../assets/product/cars/16/09.jpg",
            "../assets/product/cars/16/10.jpg",
        ],
        tags: [
            "Performante 4dr SUV AWD",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "2,890",
            " Firenze Red Metallic",
            "Light Cloud/Ebony Premium Leather",
            "Mild Hybrid",
            "Automatic",
            "395 hp",
        ],


    },
{
        id: 7021,
        title: "2023 Land Rover Discovery Sport",
        brands: "Cars",
        types: "Range Rover",
        type2: "Tops",
        price: "115,665",
        recomended: true,  
        thumbnail: "../assets/product/cars/21/01.webp",
        images: [
            "../assets/product/cars/21/01.webp",
            "../assets/product/cars/21/02.webp",
            "../assets/product/cars/21/03.webp",
            "../assets/product/cars/21/04.webp", 
            "../assets/product/cars/21/05.webp",
            "../assets/product/cars/21/06.webp",
            "../assets/product/cars/21/07.webp",
            "../assets/product/cars/21/08.webp",
            "../assets/product/cars/21/09.webp",
            "../assets/product/cars/21/10.webp",
        ],
        tags: [
            "P250 S 4dr SUV AWD",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "9,330",
            "Santorini Black Metallic",
            "Ebony Leatherette/Sueded Microfiber",
            "Gas",
            "Automatic",
            "246 hp",
        ],


    },
{
        id: 7003,
        title: "2022 Ferrari Roma",
        brands: "Cars",
        types: "Ferrari",
        type2: "Tops",
        price: "1,175,000",
        recomended: true,  
        thumbnail: "../assets/product/cars/03/01.webp",
        images: [
            "../assets/product/cars/03/01.webp",
            "../assets/product/cars/03/02.webp",
            "../assets/product/cars/03/03.webp",
            "../assets/product/cars/03/04.webp", 
            "../assets/product/cars/03/05.webp",
            "../assets/product/cars/03/06.webp",
            "../assets/product/cars/03/07.webp",
            "../assets/product/cars/03/08.webp",
            "../assets/product/cars/03/09.webp",
            "../assets/product/cars/03/10.webp",
        ],
        tags: [
            "2dr Convertible",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "1,837",
            "Argento Nurburgring Metallic",
            "Charcoal Premium Leather",
            "Gas",
            "Automated Manual",
            "661 hp",
        ],


    },

{
        id: 7012,
        title: "2019 Ferrari 488 GTB",
        brands: "Cars",
        types: "Ferrari",
        type2: "Tops",
        price: "870,999",
        recomended: true,  
        thumbnail: "../assets/product/cars/12/01.webp",
        images: [
            "../assets/product/cars/12/01.webp",
            "../assets/product/cars/12/02.webp",
            "../assets/product/cars/12/03.webp",
            "../assets/product/cars/12/04.webp", 
            "../assets/product/cars/12/05.webp",
            "../assets/product/cars/12/06.webp",
            "../assets/product/cars/12/07.webp",
            "../assets/product/cars/12/08.webp",
            "../assets/product/cars/12/09.webp",
            "../assets/product/cars/12/10.webp",
        ],
        tags: [
            "2dr Coupe ",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "24,034",
            "Bianco Avus",
            "Charcoal Premium Leather",
            "Gas",
            "Automated Manual",
            "661 hp",
        ],


    },
{
        id: 7017,
        title: "2018 Ferrari 812 Superfast",
        brands: "Cars",
        types: "Ferrari",
        type2: "Tops",
        price: "975,900",
        recomended: false,  
        thumbnail: "../assets/product/cars/17/01.webp",
        images: [
            "../assets/product/cars/17/01.webp",
            "../assets/product/cars/17/02.webp",
            "../assets/product/cars/17/03.webp",
            "../assets/product/cars/17/04.webp", 
            "../assets/product/cars/17/05.webp",
            "../assets/product/cars/17/06.webp",
            "../assets/product/cars/17/07.webp",
            "../assets/product/cars/17/08.webp",
            "../assets/product/cars/17/09.webp",
            "../assets/product/cars/17/10.webp",
        ],
        tags: [
            "2dr Coupe",
        ],
        tabel_th: [
            "Mileage",
            "Mileage",
            "Int. Color",
            "Engine",
            "Transmission",
            "Horsepower",
            
        ],
        tabel_td: [
            "28,698",
            "Rosso Corsa",
            "Cuoio Premium Leather",
            "Gas",
            "Automated Manual",
            "789 hp",
        ],


    },
{
        id: 7022,
        title: "2020 Ferrari F8 Tributo",
        brands: "Cars",
        types: "Ferrari",
        type2: "Tops",
        price: "775,900",
        recomended: false,  
        thumbnail: "../assets/product/cars/22/01.webp",
        images: [
            "../assets/product/cars/22/01.webp",
            "../assets/product/cars/22/02.webp",
            "../assets/product/cars/22/03.webp",
            "../assets/product/cars/22/04.webp", 
            "../assets/product/cars/22/05.webp",
            "../assets/product/cars/22/06.webp",
            "../assets/product/cars/22/07.webp",
            "../assets/product/cars/22/08.webp",
            "../assets/product/cars/22/09.webp",
            "../assets/product/cars/22/10.webp",
        ],
        tags: [
            "2dr Coupe",
        ],
        tabel_th: [
            "Mileage",
            "VIN",
               " Stock #",
           
            
        ],
        tabel_td: [
            "5,005",
            "ZFF92LLA2L0257111 ",
            "P1009 ",
           
        ],


    },

 
]

export default carlist;
