import React, { useState } from 'react'
import "../CSS/mission.css"


export default function Misson() {
    const our_mission = "Our Mission Our Vission"
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(prevState => !prevState);
    };
    return (
        <>

            <div className="misson" id='mission'>
                <img src="../assets/landing/mission.jpg" alt="" />
                <div className="section">
                    <div className="section_part">
                        {/* Switch */}
                        {/* Switch */}
                        {/* Switch */}
                        <div className='switch'>
                            <div className="om">
                                <h2>Our Mission</h2>
                            </div>
                            <div className="main">
                                <div className="border">
                                    <label>
                                        <input type="checkbox" onClick={handleToggle} />
                                        <div className="ourmission">
                                           
                                        </div>
                                        <span>
                                            <div className="inner">

                                                <img src="../assets/logo/logo.png" alt="" />
                                            </div>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="ov">
                                <h2>
                                    Our Vission
                                </h2>
                            </div>
                        </div>
                        {/* Our Mission */}
                        {/* Our Mission */}
                        {/* Our Mission */}
                        <div className="ourmission">
                            {isToggled ? <Content1 /> : <Content2 />}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
function Content1() {
    return (
        <>
            <div className="our_misson">
                <div className="sub">
                    <div className="supersub">
                        <h1>
                            Our Vission
                        </h1>
                        <div className="sub_phara">
                            <p>
                                Our vision at Vendomes Group is to create a world without borders,
                                where businesses of all sizes can seamlessly connect and thrive in a global
                                economy. We aspire to be the go-to international trade partner known for our
                                expertise, reliability, and commitment to ethical business practices. Through our
                                collaborative efforts, we envision a world where commerce knows no boundaries,
                                and opportunities for growth are limitless.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function Content2() {
    return (
        <>
            <div className="our_misson">
                <div className="sub">
                    <div className="supersub">
                        <h1>
                            Our Mission
                        </h1>
                        <div className="sub_phara"></div>
                        <p>
                            in Vendomes Group our mission is to be a global catalyst for economic
                            growth and prosperity. We are dedicated to simplifying international trade, fostering
                            mutually beneficial relationships, and empowering businesses to thrive in the global
                            marketplace. With unwavering commitment to quality, transparency, and
                            innovation, we aim to be the trusted partner that propels our clients towards international
                            success.
                        </p>
                    </div>
                </div>
            </div >
        </>
    )
}
