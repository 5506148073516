import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import DataList1 from '../data/data_list';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './ImageSlider.css';
import BalankHaed from '../components/BalankHaed';

function Product() {
  const params = useParams();
  const props = DataList1.find((element) => element.id === parseInt(params.id));

  const imgs = [
    { id: 0, value: props.images[0] },
    { id: 1, value: props.images[1] },
    { id: 2, value: props.images[2] },
    { id: 2, value: props.images[3] },
  ]
  const [wordData, setWordData] = useState(imgs[0])
  const [val, setVal] = useState(0)
  const handleClick = (index) => {
    console.log(index)
    setVal(index)
    const wordSlider = imgs[index];
    setWordData(wordSlider)
  }
  return (
    <>
    <BalankHaed/>
    <div className="product">
      <div className="main">
        <div className="section1">
          <div className="image_slider">
            <div className="flex_part">
              <div className='flex_row'>
                {imgs.map((data, i) =>
                  <div className="thumbnail" key={i} >
                    <img className={wordData.id == i ? "clicked" : ""} src={data.value} onClick={() => handleClick(i)} height="80" width="60" />
                  </div>
                )}
              </div>
            </div>
            <div className="img">
              <img src={wordData.value} alt="Product images" />
            </div>
          </div>
          <div className="disk">
            <div className="brand">
              <h3>
                {props.brand}
              </h3>
            </div>
            <div className="title">
              <h1>
                {props.title}
              </h1>
            </div>
            <div className="price">
              <h1>
                {props.price} AED
              </h1>
            </div>
            <div className="Short_dis">
              <p>
                {props.short_dis}
              </p>
            </div>
            <div className="enqury">
              <div className="button_border">
                  <div className='button'>
                     <h1>
                        Make Enquries
                     </h1>
                  </div>
              </div>
            </div>
            
            

          </div>

        </div>

      </div>
    </div>
    </>
   

  )
}

export default Product;