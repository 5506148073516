import "../CSS/recommened.css"
import React, { useEffect, useRef, useState } from 'react'
import { Slide} from "react-awesome-reveal";
import FoodProductCard from "../ProductCard/FoodProductCard";
import Foodlist from "../data/foodlist";
import {  useNavigate } from "react-router-dom";


const FoodRecommened = () => {
  const navigate = useNavigate();
  const arrowRef = useRef(null);


  const [selectedCategory, setSelectedCategory] = useState("all");
  const [items, setItems] = useState(Foodlist);


  const filterItems = (recomended) => {
    if (recomended === "all") {
      return items;
    } else {
      return items.filter((item) => item.recomended === recomended);
    }
  };


  const filteredItems = filterItems(true);
  filteredItems.sort((a, b) => a.id - b.id);
  const Data_list_1 = filteredItems.map((item, i) => (
    <FoodProductCard item={item} key={i} />
  ));


  return (
    <>
      <Slide direction="left">
        <div className="recomented_cover">
          <div className="cover_img">
            <img src="../assets/ourservice/foods.jpg" alt="Food" />
          </div>
          <div className="cover_text">
            <div className="cover_text_main">
            <h1>
                Foods
              </h1>
              <p>
                Vendomes Group is a leading food supplier company located in Dubai,
                UAE. As a worldwide foodstuff company, we provide a wide range of high-quality
                food products to customers across the globe. With a commitment to providing
                excellent customer service and a passion for delivering the best food products,
                Vendome has become a trusted name in the food industry.
                <br />
                <br />
                Vendomes Group has good food suppliers all around the world,
                focusing mostly on the blow category food supply.
              </p>
              <div className="more_product" role="button" onClick={() => navigate('/foods')}>
                <div className="more_product_border">
                  <div className="more_product_button">
                    <h1>More Product</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      <div className="fashion_phones_Recommented_Scroll_main">
        <div className="food_metal_electronics_Recommented_Scroll_main">
          {Data_list_1}
        </div>
      </div>
    </>
  )
}

export default FoodRecommened;
