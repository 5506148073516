import React, { useRef, useState } from 'react'
import BalankHaed from '../components/BalankHaed';
import mobileandlaptops from '../data/mobileandlaptops';
import MobileProductCard from '../ProductCard/MobileProductCard';

export const Phonesandlaptops = () => {

  const arrowRef = useRef(null);


  const [selectedCategory, setSelectedCategory] = useState("all");
  const [items, setItems] = useState(mobileandlaptops);


  const filterItems = (brands) => {
    if (brands === "all") {
      return items;
    } else {
      return items.filter((item) => item.brands !== brands);
    }
  };


  const filteredItems = filterItems("Apple22");
  filteredItems.sort((a, b) => a.id - b.id);
  const Data_list_1 = filteredItems.map((item, i) => (
    <MobileProductCard item={item} key={i} />
  ));

  return (
    <>
   
    <BalankHaed/>

      <div className="productlistinmain">
        <div className="prductlisting_main1">
          {Data_list_1}
        </div>
      </div>
    </>
  );
};
export default Phonesandlaptops ;
