export const Foodlist = [
    {
        id: 4001,
        title: "PERFUME WHITE LONG GRAIN Mahmood Rice",
        brands: "Foods",
        types: "Rice",
        type2: "",
        minqty: 20,
        price: 1200,
        maxprice: 1250,
        qty1: "20-200 Tonnes",
        qty2: ">200 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/01/01.jpg",
        images: [
            "../assets/product/food/01/01.jpg",
            "../assets/product/food/01/02.jpg",
            "../assets/product/food/01/03.jpg",
        ],
        tags: [

            "100% BROKEN PERFUME WHITE LONG GRAIN Mahmood Rice",
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Mahmood Rice",
            "Accepted",
        ],
    },
    {
        id: 4005,
        title: "Best Quality Mahmood Thai Rice Long Grain Rice",
        brands: "Foods",
        types: "Rice",
        type2: "",
        minqty: 20,
        price: 1270,
        maxprice: 1270,
        qty1: "20-200 Tonnes",
        qty2: ">200 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/05/01.jpg",
        images: [
            "../assets/product/food/05/01.jpg",
            "../assets/product/food/05/02.jpg",
            "../assets/product/food/05/03.jpg",
        ],
        tags: [
            "Best Quality Custom Made Wholesale Affordable Standard 100% Purity Mahmood Thai Rice Long Grain Rice",
            "the Essence of Culinary Excellence: Wholesale Rice - Unmatched Quality and Competitive Prices from Vietnam's Top Rice Manufacturer! Are you searching for the perfect rice to elevate your culinary creations? Look no further! We proudly present our premium selection of wholesale rice, sourced directly from Vietnam's top rice manufacturer. With an unwavering commitment to quality and a passion for perfection, we bring you the finest grains of rice that will surpass your expectations and delight your taste buds.",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Mahmood Rice",
            "Accepted",
        ],
    },
    {
        id: 4010,
        title: "Top Quality Jasmine Rice",
        brands: "Foods",
        types: "Rice",
        type2: "",
        minqty: 20,
        price: 380,
        maxprice: 1140,
        qty1: "20-200 Tonnes",
        qty2: ">200 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/10/01.jpg",
        images: [
            "../assets/product/food/10/01.jpg",
            "../assets/product/food/10/02.jpg",
            "../assets/product/food/10/03.jpg",
        ],
        tags: [
            "Top Quality Jasmine Rice for sale Cheap Price Jasmine rice and long grain fragrant rice from Jasmine Rice Long Grain Fragrant ri",
            "Key Specifications/ Special Features: Product Outline: Broken5% max Moisture14% max Foreign matter0.1% max Damaged kernels0.5% max Yellow kernels0.5% max Chalky kernels3% max Red / red streaked kernels0.5% max Immature kernels 0.2% max Glutinous0.2% max Average length of grain6.8mm min Paddy grain10 grains/kg max Purity85% min CropNew crop Mill degreeWell milled& Double Polishe",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Jasmine Rice",
            "Accepted",
        ],
    },
    {
        id: 4015,
        title: "Best Quality White Rice Thai White Rice",
        brands: "Foods",
        types: "Rice",
        type2: "",
        minqty: 20,
        price: 1000,
        maxprice: 1100,
        qty1: "20-200 Tonnes",
        qty2: ">200 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/15/01.jpg",
        images: [
            "../assets/product/food/15/01.jpg",
            "../assets/product/food/15/02.jpg",
            "../assets/product/food/15/03.jpg",
        ],
        tags: [
            "Best Quality Premium Grade White Rice Thai White Rice",
            "Product name Rich Brand QQYE Material Rich Grade A Quality	Good Our own factory OEM and ODM one-stop service",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "QQYE Rice",
            "Accepted",
        ],
    },

    {
        id: 4002,
        title: "White Granulated Sugar",
        brands: "Foods",
        types: "suger",
        type2: "",
        minqty: 50,
        price: 11400,
        maxprice: 1500,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/02/01.png",
        images: [
            "../assets/product/food/02/01.png",
            "../assets/product/food/02/02.png",
            "../assets/product/food/02/03.png",
        ],
        tags: [
            "White Granulated Sugar, Refined Sugar Icumsa 45 White suger",
            " POLARITY AT 20 DEG. CENT.	 99.80 % Minimum SULPHATED ASH CONTENT 0.04% Maximum by Weight MOISTURE 0.04% Maximum by Weight SOLUBILITY 100% Dry & free flowing GRANULOMETRY Fine",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Custom",
            "Accepted",
        ],
    },
    {
        id: 4006,
        title: "Best Grade ICUMSA 45 Sugar White/Brown at Competitive Price Suger 100% Brazil Sugar ICUMSA 45/White Refined Sugar For wholesale",
        brands: "Foods",
        types: "suger",
        type2: "",
        minqty: 50,
        price: 1150,
        maxprice: 1250,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/06/01.jpg",
        images: [
            "../assets/product/food/06/01.jpg",
            "../assets/product/food/06/02.jpg",
            "../assets/product/food/06/03.jpg",
        ],
        tags: [
            "Best Grade ICUMSA 45 Sugar White/Brown at Competitive Price Suger 100% Brazil Sugar ICUMSA 45/White Refined Sugar For wholesale",
            " POLARITY AT 20 DEG. CENT.	 99.80 % Minimum SULPHATED ASH CONTENT	 0.04% Maximum by Weight MOISTURE 0.04% Maximum by Weight SOLUBILITY 100% Dry & free flowing GRANULOMETRY Fine",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Custom",
            "Accepted",
        ],
    },
    {
        id: 4011,
        title: "Top Quality Icumsa 45 Sugar White/Brown",
        brands: "Foods",
        types: "suger",
        type2: "",
        minqty: 50,
        price: 250,
        maxprice: 250,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/food/11/01.jpg",
        images: [
            "../assets/product/food/11/01.jpg",
            "../assets/product/food/11/02.jpg",
            "../assets/product/food/11/03.jpg",
        ],
        tags: [
            " Key Specifications/ Special Features: White Sugar, Refined White Sugar, Crystal White Sugar",
            "1. High quality with best price",
            "2. Factory supply",
            "3. Prompt shipment",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "icumsa sugar",
            "Accepted",
        ],
    },
    {
        id: 4016,
        title: "White Granulated Sugar",
        brands: "Foods",
        types: "suger",
        type2: "",
        minqty: 50,
        price: 1150,
        maxprice: 1200,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/16/01.jpg",
        images: [
            "../assets/product/food/16/01.jpg",
            "../assets/product/food/16/02.jpg",
            "../assets/product/food/16/03.jpg",
        ],
        tags: [
            "White Granulated Sugar, Refined Sugar Icumsa 45 White suger",
            " ICUMSA	Extra fine Sugar ASH CONTENT 0.04  Maximum by weight MOISTURE 0.04 MAXIMUM BY WEIGHT ",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Extra fine Sugar",
            "Accepted",
        ],
    },
    {
        id: 4021,
        title: "Refined Cane Sugar Icumsa 45 Whitee",
        brands: "Foods",
        types: "suger",
        type2: "",
        minqty: 50,
        price: 1350,
        maxprice: 1450,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/21/01.jpg",
        images: [
            "../assets/product/food/21/01.jpg",
            "../assets/product/food/21/02.jpg",
            "../assets/product/food/21/03.jpg",
        ],
        tags: [
            "Refined Cane Sugar Icumsa 45 White Refined Sugar Icumsa 45 sugar s30 icumsa From Brazil in bulk for sale",
            " We are direct manufacturers and suppliers of top quantity refined icumsa 45 white and brown sugar with a lot of shipping experience world wide. Our markets include Europe, America, Asia, Hongkong, UAE, Middle East, Africa and other parts of the world. We bring you.",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "sugar",
            "Accepted",
        ],
    },
    {
        id: 4003,
        title: "Cream Milk Powder in 25 kg bags",
        brands: "Foods",
        types: "Milk Powder",
        type2: "",
        minqty: 35,
        price: 12500,
        maxprice: 13600,
        qty1: "35-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/03/01.jpg",
        images: [
            "../assets/product/food/03/01.jpg",
            "../assets/product/food/03/02.jpg",
            "../assets/product/food/03/03.jpg",
        ],
        tags: [
            "Cream Milk Powder in 25 kg bags",
            " Recombined products; including sweetened condensed milk, pasteurized milk, yoghurt and other fermented milk foods, milk cheese, cottage cheesem ice cream mixes, WHOLE MILK POWDER.",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "WHOLE MILK POWDER",
            "Accepted",
        ],
    },
    {
        id: 4007,
        title: "Cheap price skimmed Milk Powder/Milk Powder",
        brands: "Foods",
        types: "Milk Powder",
        type2: "",
        minqty: 35,
        price: 10000,
        maxprice: 11500,
        qty1: "35-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/07/01.jpg",
        images: [
            "../assets/product/food/07/01.jpg",
            "../assets/product/food/07/02.jpg",
            "../assets/product/food/07/03.jpg",
        ],
        tags: [
            "Cheap price skimmed Milk Powder/Milk Powder",
            " Wholesale price whole instant 25kg 1 bags full cream milk powder",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Skimmed Milk Powder",
            "Accepted",
        ],
    },
    {
        id: 4012,
        title: "Wholesale Instant Full Cream Milk Powder",
        brands: "Foods",
        types: "Milk Powder",
        type2: "",
        minqty: 35,
        price: 980,
        maxprice: 980,
        qty1: "35-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/12/01.jpg",
        images: [
            "../assets/product/food/12/01.jpg",
            "../assets/product/food/12/02.jpg",
            "../assets/product/food/12/03.jpg",
        ],
        tags: [
            "Wholesale Instant Full Cream Milk Powder and Full Cream Whole Milk Powder/Get Full Cream Milk Powder 25Kgs bag",
            " Product name : Whole milk powder Moisture : 2.5% max Lactose : 36.0% min Fat : 26.0% min Protein; 24.0% min Ash: 7.0% max Solubility Index: 1.0 maxTitratable Acidity: 0.15 max Colour: Cream/White Flavour: Pleasant",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "full cream whole powder milk",
            "Accepted",
        ],
    },
    {
        id: 4017,
        title: "Skimmed Whole Goat Milk Powder, Skimmed Milk / Full Cream Milk, Baby Milk Powder, Instant Full Cream",
        brands: "Foods",
        types: "Milk Powder",
        type2: "",
        minqty: 35,
        price: 250,
        maxprice: 250,
        qty1: "35-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/17/01.jpg",
        images: [
            "../assets/product/food/17/01.jpg",
            "../assets/product/food/17/02.jpg",
            "../assets/product/food/17/03.jpg",
        ],
        tags: [
            "Skimmed Whole Goat Milk Powder, Skimmed Milk",
            " Wholesale price whole instant 25kg 1 bags full cream milk powder Product Name Wholesale price whole instant 25kg 1 bags full cream milk powder",

            "Type Dried Whole Milk",

            "Appearance white powder to little yellow powder",

            "Purity 99% ",
            "min full cream milk powder",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Skimmed Milk Powder",
            "Accepted",
        ],
    },
    {
        id: 4022,
        title: "Buy Instant Full Cream Milk Online",
        brands: "Foods",
        types: "Milk Powder",
        type2: "",
        minqty: 50,
        price: 1200,
        maxprice: 1400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/food/22/01.jpg",
        images: [
            "../assets/product/food/22/01.jpg",
            "../assets/product/food/22/02.png",
            "../assets/product/food/22/03.jpg",
        ],
        tags: [
            "Buy Instant Full Cream Milk Online / Wholesale Whole Milk Powder / Skimmed Milk Powder in 25Kg Bags",
            " POLARITY AT 20 DEG. CENT.	 99.80 % Minimum",
            "SULPHATED ASH CONTENT	 0.04% Maximum by Weight",
            "MOISTURE	 0.04% Maximum by Weight",
            "SOLUBILITY	 100% Dry & free flowing",
            "GRANULOMETRY	 Fine",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Custom",
            "Accepted",
        ],
    },
    {
        id: 4004,
        title: "Wholesale Sunflower oil Refined",
        brands: "Foods",
        types: "Oil",
        type2: "",
        minqty: 50,
        price: 5300,
        maxprice: 6200,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/04/01.jpg",
        images: [
            "../assets/product/food/04/01.jpg",
            "../assets/product/food/04/02.jpg",
            "../assets/product/food/04/03.jpg",
        ],
        tags: [
            "Wholesale Sunflower oil Refined Edible Sunflower Cooking Oil Refined Sunflower Oil",
            " Our advanced quality ranges of Refined Sunflower are widely used for cooking applications and are supplied in secure packs. These are available in various quantity and are supplied on wide market scale. We ensure secure, reliable, advanced quality crude and rSunflower oil for sale",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "OLEINA",
            "Accepted",
        ],
    },
    {
        id: 4008,
        title: "Wholesale Sunflower oil Refined",
        brands: "Foods",
        types: "Oil",
        type2: "",
        minqty: 50,
        price: 5600,
        maxprice: 6000,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/04/01.jpg",
        images: [
            "../assets/product/food/04/01.jpg",
            "../assets/product/food/04/02.jpg",
            "../assets/product/food/04/03.jpg",
        ],
        tags: [
            "Wholesale Sunflower oil Refined Edible Sunflower Cooking Oil Refined Sunflower Oil",
            " Our advanced quality ranges of Refined Sunflower are widely used for cooking applications and are supplied in secure packs. These are available in various quantity and are supplied on wide market scale. We ensure secure, reliable, advanced quality crude and rSunflower oil for sale",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Soybeans cooking oil",
            "Accepted",
        ],
    },
    {
        id: 4013,
        title: "High Vitamin E Factory Price Edible Sunflower oil",
        brands: "Foods",
        types: "Oil",
        type2: "",
        minqty: 50,
        price: 6300,
        maxprice: 7000,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/13/01.jpg",
        images: [
            "../assets/product/food/13/01.jpg",
            "../assets/product/food/13/02.jpg",
            "../assets/product/food/13/03.jpg",
        ],
        tags: [
            "High Vitamin E Factory Price Edible Sunflower oil Refined 1L Bottle Bulk Packaging flexytank",
            " Our sunflower oil are selected light yellow seeds, light flavor and aroma, perfectly suited to today, especially suitable for use in cold sauces and seasonings, making it ideal for light diet consumption, healthy and balanced.",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Sunflower Oil",
            "Accepted",
        ],
    },
    {
        id: 4018,
        title: "Wholesale High Quality Soy Bean Oil",
        brands: "Foods",
        types: "Oil",
        type2: "",
        minqty: 50,
        price: 5300,
        maxprice: 6300,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/18/01.jpg",
        images: [
            "../assets/product/food/18/01.jpg",
            "../assets/product/food/18/02.jpg",
            "../assets/product/food/18/03.jpg",
        ],
        tags: [
            "Wholesale High Quality Soy Bean Oil 100%",
            " Soybean oil has always been a popular oil for cooking, as an inexpensive, healthy oil with a high smoke point. It contains higher levels of poly-unsaturated fats than other oils such as rapeseed, giving our Soybean oil the special character of healthy oil. Soybean oil is a nutritious and delicious cooking oil, also providing a rich source for vitamin E, which can reduce the risk of heart disease. All products comply with authorized standards and satisfy all the requirements of the European Union ( 4492:2005; meets all ISO 22000 requirements).",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Refined soyabeans Oil",
            "Accepted",
        ],
    },
    {
        id: 4023,
        title: "Wholesale Sunflower oil Refined",
        brands: "Foods",
        types: "Oil",
        type2: "",
        minqty: 50,
        price: 6000,
        maxprice: 7500,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/food/23/01.jpg",
        images: [
            "../assets/product/food/23/01.jpg",
            "../assets/product/food/23/02.png",
            "../assets/product/food/23/03.jpg",
        ],
        tags: [
            "Wholesale Sunflower oil Refined Edible Sunflower Cooking Oil Refined Sunflower Oil",
            " Organic Canola Oil is used in food manufacturing where a certified organic, light tasting oil is required.  It is ideal for those in the natural, because it is GMO Free.  It has a light, neutral flavor, light texture and high heat tolerance which makes it ideal for sauteing, baking and frying. omega-3 fatty acids and low in saturated, with no trans fats and cholesterol. We different type of 100% pure Sunflower & Corn oil which makes great flavor of your foods comes though. In order to achieve this mission and to have a better understanding of consumer tastes and demand, we continuously practice market research activities in each market we operate, tailor making every single product for each market`s individual needs",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Refined Canola Oil",
            "Accepted",
        ],
    },

    {
        id: 4009,
        title: "Wheat, Fodder corn",
        brands: "Foods",
        types: "Wheats",
        type2: "",
        minqty: 40,
        price: 1500,
        maxprice: 1600,
        qty1: "40-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/09/01.jpg",
        images: [
            "../assets/product/food/09/01.jpg",
            "../assets/product/food/09/02.png",
            "../assets/product/food/09/03.jpg",
        ],
        tags: [
            "Wheat, Fodder corn, Fodder Barley/ Wheat flour / Grains From Ukraine / Wheat Grains",
            "Wholesale Top Grade A Organic Whole Wheat Grain For Sale / High Quality Wheat / Wheat Grain / Ukraine Wheat for Sale / Barley Wholesale Top Grade A Organic Whole Wheat Grain For Sale / High Quality Wheat / Wheat Grain / Ukraine Wheat for Sale / Barley We are one of the top organizations that manufacture and supply high quality Wheat Flour. Our wheat flour is made from premium grade Wheat that is carefully selected and processed using modern technology to ensure that no impurities are there. We pack freshly grinned Wheat Flour to ensure nutrients are kept intact.",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Ukraine Wheat",
            "Accepted",
        ],
    },
    {
        id: 4014,
        title: "High Quality Wheat Seed",
        brands: "Foods",
        types: "Wheats",
        type2: "",
        minqty: 40,
        price: 2000,
        maxprice: 2250,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/14/01.jpg",
        images: [
            "../assets/product/food/14/01.jpg",
            "../assets/product/food/14/02.png",
            "../assets/product/food/14/03.jpg",
        ],
        tags: [
            "High Quality Wheat Seed & Wheat Flour For Export, Fodder corn, Fodder Barley/ Wheat flour",
            "Wholesale Top Grade A Organic Whole Wheat Grain For Sale / High Quality Wheat / Wheat Grain / Ukraine Wheat for Sale / Barley Wholesale Top Grade A Organic Whole Wheat Grain For Sale / High Quality Wheat / Wheat Grain / Ukraine Wheat for Sale / Barley We are one of the top organizations that manufacture and supply high quality Wheat Flour. Our wheat flour is made from premium grade Wheat that is carefully selected and processed using modern technology to ensure that no impurities are there. We pack freshly grinned Wheat Flour to ensure nutrients are kept intact. We strictly follow industry guidelines to make sure that our wheat flour is safe and hygienic for consumption.",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "Ukraine Wheat",
            "Accepted",
        ],
    },

    {
        id: 4019,
        title: "Whole Wheat Grains",
        brands: "Foods",
        types: "Wheats",
        type2: "",
        minqty: 40,
        price: 2100,
        maxprice: 2300,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/19/01.jpg",
        images: [
            "../assets/product/food/19/01.jpg",
            "../assets/product/food/19/02.png",
            "../assets/product/food/19/03.jpg",
        ],
        tags: [
            "Whole Wheat Grains",
            "We are  dealing and  producing high quality of Wheat, Rice, Sugar, Sunflower oil, Corn Oil, Palm Oil, Olive Oil, canola oil, Salt, Coffee, Natural Mineral Water, Soybean Oil, Soybean, Lentils, Spices, Grain, beans, Dairy products, Beverages, We  believe  in giving good  quality service and  always good  products",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "",
            "Accepted",
        ],
    },
    {
        id: 4024,
        title: "High Quality Wheat",
        brands: "Foods",
        types: "Wheats",
        type2: "",
        minqty: 40,
        price: 900,
        maxprice: 1200,
        qty1: "50-500 Tonnes",
        qty2: ">500 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/food/24/01.jpg",
        images: [
            "../assets/product/food/24/01.jpg",
            "../assets/product/food/24/02.png",
            "../assets/product/food/24/03.jpg",
        ],
        tags: [
            "High Quality Wheat / Wheat Grain / Ukraine Wheat for Sale / Milling Wheat",
            "Commodity: Milling wheatProduct type: FoodStyle: DriedTaste: SweatColor: BrownMoisture: 13% maxProtein: 12% minQuality:PremiumForeign materials: 0.5% maxDamaged Grain: 2% maxCultivation Type: CommonProcessing Type: MillingPacking - 25 Kg or 50 Kg pp bagPrinting bag - As per the buyer needsLoading capacity of the container - 20 ft container (26 Mt)",
        ],
        tabel_th: [
            "Brand Name",
            "Small Orders",
        ],
        tabel_td: [
            "OEM",
            "Accepted",
        ],
    },
]

export default Foodlist;
