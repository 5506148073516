export const ElectronicsDevicesParts = [
    {
    id: 3001,
    title: "IGBT Module Dga75h170m2t",
    brands: "DGA75H170M2T",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/01/01.webp",
    images: [
        "../assets/product/electronic/01/01.webp",
    ],
    tags: [
        "IGBT Module Dga75h170m2t",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dga75h170m2t",
        "Wxdh",
        "Wuxi, China",
    ],


},
{
    id: 3012,
    title: "IGBT Module Dgc40c120m2t",
    brands: "DGC40C120M2T",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 105,
    maxprice: 120,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/12/01.webp",
    images: [
        "../assets/product/electronic/12/01.webp",
    ],
    tags: [
        "IGBT Module Dgc40c120m2t",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3024,
    title: "75A 1200V Half Bridge IGBT Module",
    brands: "DGA75H120M2T",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/24/01.webp",
    images: [
        "../assets/product/electronic/24/01.webp",
    ],
    tags: [
        "75A 1200V Half Bridge IGBT Module",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dga75h100m2t",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3036,
    title: "100A 1200V Half Bridge IGBT Module",
    brands: "DGA100H120M2T",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/36/01.jpg",
    images: [
        "../assets/product/electronic/36/01.jpg",
    ],
    tags: [
        "100A 1200V Half Bridge IGBT Module",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dga100h100m2t",
        "Wxdh",
        "Wuxi, China",
    ],


},
{
    id: 3048,
    title: "50A 1200V Half Bridge IGBT Module",
    brands: "DHG50N120D",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/48/01.jpg",
    images: [
        "../assets/product/electronic/48/01.jpg",
    ],
    tags: [
        "50A 1200V Half Bridge IGBT Module",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dhg50n120d",
        "Wxdh",
        "Wuxi, China",
    ],


},

{

    id: 3002,
    title: " 30V N-Channel Enhancement",
    brands: "30H10K",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/02/01.webp",
    images: [
        "../assets/product/electronic/02/01.webp",
    ],
    tags: [
        " 30V N-Channel Enhancement Mode Power Mosfet 30h10K to-252b",
        "100A 30V N-Channel Enhancement Mode Power Mosfet 30h10K to-252b",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "30h10K",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3013,
    title: "100A 68V N-Channel Enhancement",
    brands: "DGA75H170M2T",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/13/01.jpg",
    images: [
        "../assets/product/electronic/13/01.jpg",
    ],
    tags: [
        "100A 68V N-Channel Enhancement Mode Power Mosfet Dh100n06f to-220",
        "100A 68V N-Channel Enhancement Mode Power Mosfet Dh100n06f to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "DH100N06F",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3025,
    title: "60A 100V N-Channel Enhancement",
    brands: "60N10",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/25/01.jpg",
    images: [
        "../assets/product/electronic/25/01.jpg",
    ],
    tags: [
        "60A 100V N-Channel Enhancement Mode Power Mosfet 60n10 to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "60n10",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3037,
    title: "25A 100V N-Channel Enhancement",
    brands: "25N10",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/37/01.jpg",
    images: [
        "../assets/product/electronic/37/01.jpg",
    ],
    tags: [
        "25A 100V N-Channel Enhancement Mode Power Mosfet 25n10 to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "25n10",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3049,
    title: "10A 600V N-Channel Enhancement",
    brands: "10N60",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/49/01.jpg",
    images: [
        "../assets/product/electronic/49/01.jpg",
    ],
    tags: [
        "10A 600V N-Channel Enhancement Mode Power Mosfet 10n60 to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "10n60",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3061,
    title: "7.5A 600V N-Channel Enhancement",
    brands: "8N60",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/61/01.jpg",
    images: [
        "../assets/product/electronic/61/01.jpg",
    ],
    tags: [
        "7.5A 600V N-Channel Enhancement Mode Power Mosfet 8n60 to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "8n60",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3073,
    title: "10A 650V N-Channel Enhancement",
    brands: "10N65",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/73/01.jpg",
    images: [
        "../assets/product/electronic/73/01.jpg",
    ],
    tags: [
        "10A 650V N-Channel Enhancement Mode Power Mosfet 10n65 to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "10n65",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3085,
    title: "110A 30V N-Channel Enhancement",
    brands: "110N03",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/85/01.jpg",
    images: [
        "../assets/product/electronic/85/01.jpg",
    ],
    tags: [
        "110A 30V N-Channel Enhancement Mode Power Mosfet 110n03 to-220",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "110N03",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3097,
    title: "640 to-220 18A 200V N-Channel Enhancement Mode Power Mosfet",
    brands: "640",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/97/01.jpg",
    images: [
        "../assets/product/electronic/97/01.jpg",
    ],
    tags: [
        "640 to-220 18A 200V N-Channel Enhancement Mode Power Mosfet",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "640",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3109,
    title: "15A 40V P-Channel Enhancement Mode Power Mosfet Aod413 to-252",
    brands: "AOD413",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/109/01.jpg",
    images: [
        "../assets/product/electronic/109/01.jpg",
    ],
    tags: [
        "15A 40V P-Channel Enhancement Mode Power Mosfet Aod413 to-252",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Aod413",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3003,
    title: "6A 650V Sic Schottky Diode",
    brands: "SICF0665",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/03/01.jpg",
    images: [
        "../assets/product/electronic/03/01.jpg",
    ],
    tags: [
        "6A 650V Sic Schottky Barrier Diode Sicf0665 to-220-2L",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Sicf066560",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3014,
    title: "16A 650V Sic Barrier Diode",
    brands: "SIC1665",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/14/01.jpg",
    images: [
        "../assets/product/electronic/14/01.jpg",
    ],
    tags: [
        "16A 650V Sic Schottky Barrier Diode Sic1665",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Sic1665",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3026,
    title: "8A 650 V Sic Barrier Diode",
    brands: "SDS065J008S3",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/26/01.jpg",
    images: [
        "../assets/product/electronic/26/01.jpg",
    ],
    tags: [
        "8A 650 V Sic Schottky Barrier Diode SDS065j008s3",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "SDS065j008s3",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3038,
    title: "6A 650V Sic Barrier Diode",
    brands: "SDS065J006S3",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/38/01.jpg",
    images: [
        "../assets/product/electronic/38/01.jpg",
    ],
    tags: [
        "6A 650V Sic Schottky Barrier Diode SDS065j006s3",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "SDS065j008s3",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3050,
    title: "60A 650V Sic Barrier Diode",
    brands: "DCC60D65G3",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/50/01.jpg",
    images: [
        "../assets/product/electronic/50/01.jpg",
    ],
    tags: [
        "60A 650V Sic Schottky Barrier Diode Dcc60d65g3",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dcc60d65g3",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3062,
    title: "20A 650V Sic Barrier Diode",
    brands: "DCCT20D65G4",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/62/01.jpg",
    images: [
        "../assets/product/electronic/62/01.jpg",
    ],
    tags: [
        "20A 650V Sic Schottky Barrier Diode Dcct20d65g4",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dcct20d65g4",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3074,
    title: "5A 1200V Sic Schottky Diode",
    brands: "DCGT05D120G3",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/74/01.jpg",
    images: [
        "../assets/product/electronic/74/01.jpg",
    ],
    tags: [
        "5A 1200V Sic Schottky Barrier Diode Dcgt05D120g3",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "DCGT05D120G3",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3086,
    title: "20A 1200V Sic Sttky Barrier Diode",
    brands: "DCCT20D120G4",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/86/01.jpg",
    images: [
        "../assets/product/electronic/86/01.jpg",
    ],
    tags: [
        "20A 1200V Sic Schottky Barrier Diode Dcct20d120g4",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dcct20d120g4",
        "Wxdh",
        "Wuxi, China",
    ],


},
{
    
    id: 3098,
    title: "25A 1700V Sic SchottkyDiode",
    brands: "DCCT25D170G1",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: true,
    thumbnail: "../assets/product/electronic/98/01.jpg",
    images: [
        "../assets/product/electronic/98/01.jpg",
    ],
    tags: [
        "25A 1700V Sic Schottky Barrier Diode Dcct25D170g1",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dcct25D170g1",
        "Wxdh",
        "Wuxi, China",
    ],


},
{

    id: 3110,
    title: "4A 650V Sic Schottky Diode",
    brands: "DCD04D65G4",
    types: "Electronics Parts",
    type2: "N-type Semiconductor",
    minqty: 5000,
    price: 0.50,
    maxprice: 2.5,
    qty1: "5,000-25,000 Pieces",
    qty2: ">25,000 Pieces",
    recomended: false,
    thumbnail: "../assets/product/electronic/110/01.jpg",
    images: [
        "../assets/product/electronic/110/01.jpg",
    ],
    tags: [
        "4A 650V Sic Schottky Barrier Diode Dcd04D65g4 to-252package",
    ],
    tabel_th: [
        "Model",
        "Brand",
        "Origin",
    ],
    tabel_td: [
        "Dcd04D65g4",
        "Wxdh",
        "Wuxi, China",
    ],


},
]

export default ElectronicsDevicesParts;
