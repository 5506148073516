import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(true);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (

    <>
      <nav className='navbar'>
        <div className="navbar_main">
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src="../assets/logo/inqueri.png" alt="" />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <a
               href='/'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Home
              </a>
            </li>
            <li
              className='nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={closeMobileMenu}
            >
              <a
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Our Categories <i className='fas fa-caret-down fa-caret-dow-gap' />
              </a>
              {dropdown && <Dropdown />}
            </li>

            <li className='nav-item'>
              <a
                href='/#mission'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Our Mission
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='/#aboutus'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About Us
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='/#contactus'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact Us
              </a>
            </li>
            <li>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
