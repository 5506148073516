import "../CSS/contactus.css"
import React from 'react'

export default function Contactus() {
    return (
        <div className="contactus" id="contactus">
            <div className="contactus_cover">
                <img src="../assets/landing/contactus.jpg" alt="Dubai Light Images" />
            </div>
            <div className="contactus_main">
                <div className="contactus_sub1">
                    <h1>Contact US</h1>
                    <p>
                        We're here to assist you. Please don't hesitate to reach out.
                    </p>
                    <div className="socialmedia">
                        <div className="logo">
                            <img src="../assets/socilmedia/call.png" alt="" />
                        </div>
                        <div className="text">
                            <p>+971 4 529 9538</p>
                        </div>
                    </div>
                    <div className="socialmedia">
                        <div className="logo">
                            <img src="../assets/socilmedia/mail.png" alt="" />
                        </div>
                        <div className="text">
                            <p>generaltrading@vendomesgroup.ae</p>
                        </div>
                    </div>
                    <div className="socialmedia">
                        <div className="logo">
                            <img src="../assets/socilmedia/location.png" alt="" />
                        </div>
                        <div className="text">
                            <p>Office Number:206, Oxford Tower, Business Bay, Dubai, UAE.</p>
                        </div>
                    </div>

                    <div className="locationhere">
                        <div className="locationhere_main">
                            <p>Visite Our Office - Location</p>
                            <a href="https://maps.app.goo.gl/etVGHFwGieQqxWVSA">
                                <div className="location_buttion">
                                    <div className="button_border">
                                        <h1>Click Here</h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}
