import React from 'react'
import "../CSS/abutus.css"
import { Slide, Zoom } from 'react-awesome-reveal'

export default function AboutUs() {
  return (
    <div className="aboutus1" id='aboutus'>
      <div className="aboutus_img">
        <img src="../assets/landing/abutus.png" alt="" />
      </div>
      <div className="main">
        <div className="aboutus_main">
          <div className="heading">
            <div className="aboutus_main_heading">
              <h1>
                About Us
              </h1>
            </div>
          </div>
          <div className="title">
            <div className="tags">
              <p>
                Vendomes General Trading is an An     ancient company that has been passed
                down through generations which is a family Business was established in 1948 in
                New York city , And from date of 2020 it is run by the Grandson Mr Ait Ahemd
                Laamara in the UAE. and has since then developed into one of the leading companies
                in the world Based in Algeria , China , Turkey and The UAE , actively engaged
                in the field of General Trading LLC & Import and Export Services, Luxury and
                Exotic cars Rental & Trading , Mobile and Laptops, Electronics Parts.
              </p>
              <br />
              <p>
                Vendomes service is built on ethical approach in delivering total solutions and
                getting it right the first time. We work with our clients and encourage a direct line
                of communication with them and our highly skilled workforce.
              </p>
              <br />
              <p>
                We strive to make sure that our clients are actively involved, informed and consulted.
                We ask, we listen and we respond to the needs and ensure the satisfaction
                of our Clients and our Team.
              </p>
              <br />
              <p>
                At the heart of Vendomes reside its founder's values and dedication embracing
                qualities like integrity, vision and innovation that drives it forward as a business
                entity and deemed responsible to contribute to the society where it operates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
