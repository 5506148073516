import React from 'react'
import "../CSS/foodproductcard.css"
import { useNavigate } from 'react-router-dom';

export default function FoodProductCard(props) {
    const navigate = useNavigate();
    const { thumbnail, title,product_id, price, id } = props.item;
    return (
        <div className="food_metal_electronics_productcard"  role="button" onClick={() => navigate(`/foods/${id}`)}>
            <div className="food_metal_electronics_main">
                <div className="food_metal_electronics_img">
                    <img src={thumbnail} alt="" />
                </div>
                <div className="food_metal_electronics_productcard_disc">
                    <div className="food_metal_electronics_disc_modelnumber">
                        <h6>#{id}</h6>
                    </div>
                    <div className="food_metal_electronics_disc_price">
                        <h1>{price} AED</h1>
                    </div>
                    <div className="food_metal_electronics_disc_title">
                        <h6>{title}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
