import '../CSS/Footer.css';
import React from 'react'

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="main">
          <div className="main_sub">
            <div className="sub1">
              <div className="logo">
                <div className="logo_main">
                  <img src="../assets/logo/inqueri.png" alt="" />
                </div>
                <div className="QuickAcess">
                  <div className="QuickAces_main">
                    <div className="QuickAces_sub">
                      <a href='../#ourcategory'><h1>Our Category</h1></a>
                      <a href="../foods"><h2>Food Products</h2></a>
                      <a href="../fashion"><h2>Fahaion's cloths</h2></a>
                      <a href="../electronic"><h2>Electronics parts</h2></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="social_media1">
                <div className="socialmedia_main1">
                  <h1>
                    Follow Our Social Media
                  </h1>
                  <div className="socil_media_icon">
                    <a href="https://twitter.com/VendomesT">
                      <div className="social">
                        <img src="../assets/socilmedia/twitter.png" alt="" />
                      </div>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61552360671753">
                      <div className="social">
                        <img src="../assets/socilmedia/facebook.png" alt="" />
                      </div>
                    </a>
                    <a href="https://www.instagram.com/">
                      <div className="social">
                        <img src="../assets/socilmedia/instagram.png" alt="" />
                      </div>
                    </a>
                    <a href="https://t.me/VendomesGeneralTraading">
                      <div className="social">
                        <img src="../assets/socilmedia/telegram.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <h1>
                    Contect Us
                  </h1>
                  <div className="email">
                    <h4>generaltrading@vendomesgroup.ae</h4>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div className="sub3">
            <p>
              © 2023 <span className='gold'>VENDOMES GENERAL TARDINGS LLC</span>. All Rights Reserved by <span className='gold'>VENDOMS GROUP</span>
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
