
import React, { useRef, useState } from 'react'
import Foodlist from '../data/foodlist';
import FoodProductCard from '../ProductCard/FoodProductCard';
import BalankHaed from '../components/BalankHaed';

export const Foods = () => {

  const arrowRef = useRef(null);


  const [selectedCategory, setSelectedCategory] = useState("all");
  const [items, setItems] = useState(Foodlist);


  const filterItems = (brands) => {
    if (brands === "all") {
      return items;
    } else {
      return items.filter((item) => item.brands === brands);
    }
  };


  const filteredItems = filterItems("Foods");
  filteredItems.sort((a, b) => a.id - b.id);
  const Data_list_1 = filteredItems.map((item, i) => (
    <FoodProductCard item={item} key={i} />
  ));

  return (
    <>
    <BalankHaed/>
      <div className="productlistinmain">
        <div className="prductlisting_main1">
          {Data_list_1}
        </div>
      </div>
    </>
  );
};
export default Foods;
