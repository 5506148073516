import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './foodproduct.css';
import { useParams } from 'react-router-dom';
import metaldata from '../data/metal_data';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import BalankHaed from '../components/BalankHaed';

function MetalProduct() {
    const params = useParams();
    const props = metaldata.find((element) => element.id === parseInt(params.id));

    const [popup, setPop] = useState(false);
    const [popup1, setPop1] = useState(false);
    const handleClickOpen = () => {
        setPop(!popup);
    }
    const closePopup = () => {
        setPop(false);
        setPop1(false)
    }

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_uy9e7sn', 'template_9p4xxdq', form.current, 'HDs7QPwDjIodmL48a')
            .then((result) => {
                console.log(result.text);
                setPop(false);
                setPop1(true);
            }, (error) => {
                console.log(error.text);
                setPop(false);
                setPop1(false);
            });
    };

    const imgs = [
        { id: 0, value: props.images[0] },
        { id: 1, value: props.images[1] },
        { id: 2, value: props.images[2] },
    ]
    const [wordData, setWordData] = useState(imgs[0])
    const [val, setVal] = useState(0)
    const handleClick = (index) => {
        console.log(index)
        setVal(index)
        const wordSlider = imgs[index];
        setWordData(wordSlider)
    }
    const handleNext = () => {
        let index = val < imgs.length - 1 ? val + 1 : val;
        setVal(index)
        const wordSlider = imgs[index];
        setWordData(wordSlider)
    }
    const handlePrevious = () => {
        let index = val <= imgs.length - 1 && val > 0 ? val - 1 : val;
        setVal(index)
        const wordSlider = imgs[index];
        setWordData(wordSlider)
    }
    const urls = `https:/vendomesgroup.ae/metals/${props.id}`;

    return (
        <>
        <BalankHaed/>
            <div className="food_product">
                <div className="main">
                    <div className="section1_bg_img">
                        <div className="img">
                            <img src={wordData.value} alt="product images" />
                        </div>
                    </div>
                    <div className="section1">
                        <div className="section1_main">
                            <div className="sub">
                                <div className="imagesection">
                                    <div className="product_image">
                                        <div className="product_image_main_main">
                                            <div className="product_image_main">
                                                <div className="product_image_top">
                                                    <button className='back' onClick={handlePrevious}><IoIosArrowBack /></button>
                                                    <div className="img">
                                                        <img src={wordData.value} />
                                                    </div>
                                                    <button className='next' onClick={handleNext}><IoIosArrowForward /></button>
                                                </div>
                                                <div className='flex_row'>
                                                    <div className="sub">
                                                        {imgs.map((data, i) =>
                                                            <div className="thumbnail" key={i} >
                                                                <div className="thumbnail_image">
                                                                    <img className={wordData.id == i ? "clicked" : ""} src={data.value} onClick={() => handleClick(i)} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="textsection">
                                    <div className="main">
                                        <div className="product_heading">
                                            <div className="product_heading_brand">
                                                <h2>{props.brands} / {props.types}</h2>
                                            </div>
                                            <div className="product_heading_tilte">
                                                <h1>{props.title}</h1>
                                            </div>
                                            <div className="product_heading_commen">
                                                <h3><span className='span_id'>Product Id : </span>#{props.id}</h3>
                                            </div>
                                            <div className="product_price">
                                                <div className="product_price_heading">
                                                    <h1>{props.price} AED <span className='span_price'>/ Tonne</span></h1>
                                                    <h1>{props.minqty} Tonnes <span className='span_price'> Minimum Order</span></h1>
                                                </div>
                                                <div className="product_price_table">
                                                    <tbody>
                                                        <tr>
                                                            <th >Quantity</th>
                                                            <td >Price</td>
                                                        </tr>
                                                        <tr>
                                                            <th >{props.qty1}</th>
                                                            <td >{props.maxprice} AED</td>
                                                        </tr>
                                                        <tr>
                                                            <th >{props.qty2}</th>
                                                            <td >{props.price} AED</td>
                                                        </tr>
                                                    </tbody>
                                                </div>
                                            </div>

                                            <div className="inquire_now_button_border">
                                                <div className="inquire_now_button" type='button' onClick={handleClickOpen}>
                                                    <h1>Inqure Now</h1>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section3">
                        <div className="sub">

                            <div className="detail_text">
                                <div className="detail_text_main">
                                    <div className="product_heading_tilte">
                                        <h1>Product Deatail</h1>
                                    </div>
                                    {props.tags.length > 0 ? (
                                        props.tags.map((item, index) => (
                                            <div key={index}>
                                                <p># {props.tags[index]}</p>
                                            </div>
                                        ))
                                    ) : (
                                        // Render a message when there is no data
                                        <p></p>
                                    )}
                                </div>
                            </div>
                            <div className="detail_table">
                                <tbody>

                                    {props.tabel_th.length > 0 ? (
                                        props.tabel_th.map((item, index) => (
                                            <tr key={index}>
                                                <th>{props.tabel_th[index]}</th>
                                                <td>{props.tabel_td[index]}</td>
                                                {/* Add more data cells as needed */}
                                            </tr>
                                        ))
                                    ) : (
                                        // Render a message when there is no data
                                        <tr>
                                            <td colSpan="2">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* INQUERY NOW*/}
            {/* INQUERY NOW*/}
            {/* INQUERY NOW*/}
            {
                popup ?
                    <div className="inqury-now-main">
                        <div className="inqury-now-popup">
                            <div className="inqury-now-popup-header">
                                <div className="inqury-now-popup-header-logo">
                                    <img src="../assets/logo/inqueri.png" alt="Vendomes Logo" />
                                </div>
                            </div>
                            <div className="inqury-now-popup-select-product-details_main">
                                <div className="inqury-now-popup-select-product-details">
                                    <div className="inqury-now-popup-select-product-details-images">
                                        <img src={props.images[0]} alt="Product-Thumbnail-image" />
                                    </div>
                                    <div className="inqury-now-popup-select-product-details-delails_main">
                                        <div className="inqury-now-popup-select-product-details-delails">
                                            <h1 name="from_title">{props.title}</h1>
                                            <div className="product_heading_inqury_commen">
                                                <h3><span className='inqury_span_id' name="from_id" >Product Id : </span>#{props.id}</h3>
                                                <h3><span className='inqury_span_id'>Minimum Order : </span>{props.minqty}</h3>
                                                <h3><span className='inqury_span_id'>{props.qty1} : </span>{props.maxprice}</h3>
                                                <h3><span className='inqury_span_id'>{props.qty2} : </span>{props.price}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form ref={form} onSubmit={sendEmail}>
                                <div className="inqury-now_input_main">
                                    <div className="inqury-now_input_sub">
                                        <div className="row-details-main">
                                            <div className='row-details'>
                                                <div className="inqury-now-popup-details">
                                                    <div className="inputBox">
                                                        <input type="text" required="required" name="from_name" placeholder='Full Name' />
                                                    </div>
                                                </div>
                                                <div className="inqury-now-popup-details">
                                                    <div className="inputBox">
                                                        <input type="text" required="required" name="from_quntity" placeholder='Quantity' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row-details'>
                                                <div className="inqury-now-popup-details">
                                                    <div className="inputBox">
                                                        <input type="text" required="required" name="from_email" placeholder='Email' />
                                                    </div>
                                                </div>
                                                <div className="inqury-now-popup-details">
                                                    <div className="inputBox">
                                                        <input type="text" required="required" name="from_phone" placeholder='Phone Number' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row-wapper'>
                                                <div className="messege-outline">
                                                    <h3>More Details</h3>
                                                </div>
                                                <textarea placeholder='' name="message"> </textarea>
                                            </div>
                                        </div>


                                        <div className="classblock">
                                            <input type="text" value={props.id} name="from_id" readOnly={true} />
                                            <input type="text" value={props.title} name="from_title" readOnly={true} />
                                            <input type="text" value={urls} name="from_url" readOnly={true} />
                                        </div>
                                        <div className="button-row">
                                            <input type="submit" value="Send" className='submit-button' />
                                            <input
                                                type="submit"
                                                value="Cancel"
                                                className='cancel-button'
                                                onClick={closePopup} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> : ""
            }
            {
                popup1 ?
                    <div className="Thankyou_popup">
                        <div className="Thankyou_popup_bg">
                            <div className="Thankyou_popup_bg_img">
                                <img src="../assets/thankyou/01.jpg" alt="Ship" />
                            </div>
                            <div className="Thankyou_popup_bg-cover">
                                <div className="Thankyou_popup_main">
                                    <div className="Thankyou_popup_sub">
                                        <div className="Thankyou_popup_logo">
                                            <img src="../assets/logo/inqueri.png" alt="" />
                                        </div>
                                        <div className="Thankyou_popup_thank_you">
                                            <h2>Thank you for reaching out to us! We appreciate your interest and will get back to you as soon as possible. In the meantime, feel free to explore more of our website or check out our latest updates.</h2>
                                        </div>
                                        <div className="button-row">
                                            <input type="button" value="Go Back" className='submit-button' onClick={closePopup} />
                                        </div>
                                        <div className="Thankyou_popuppayments">
                                            <h1>We are pleased to accept</h1>
                                        </div>
                                        <div className="Thankyou_popuppayments_logo">
                                            <div className="Thankyou_popuppayments_logo_thumb">
                                                <img src="../assets/thankyou/payments/01.png" alt="" />
                                            </div>
                                            <div className="Thankyou_popuppayments_logo_thumb">
                                                <img src="../assets/thankyou/payments/02.png" alt="" />
                                            </div>
                                            <div className="Thankyou_popuppayments_logo_thumb">
                                                <img src="../assets/thankyou/payments/03.png" alt="" />
                                            </div>
                                            <div className="Thankyou_popuppayments_logo_thumb">
                                                <img src="../assets/thankyou/payments/04.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {/* INQURY END */}
            {/* INQURY END */}
            {/* INQURY END */}

        </>

    );
}
export default MetalProduct;