import React, { useRef, useState } from 'react'
import BalankHaed from '../components/BalankHaed'
import CarProductCard from '../ProductCard/CarProductCard ';
import carlist from '../data/cars_data';

export default function Car() {
    const arrowRef = useRef(null);


    const [selectedCategory, setSelectedCategory] = useState("all");
    const [items, setItems] = useState(carlist);


    const filterItems = (brands) => {
        if (brands === "all") {
            return items;
        } else {
            return items.filter((item) => item.brands === brands);
        }
    };
    const filteredItems = filterItems("Cars");
    filteredItems.sort((a, b) => a.id - b.id);
    const Data_list_1 = filteredItems.map((item, i) => (
        <CarProductCard item={item} key={i} />
    ));

    
    return (
        <>
            <BalankHaed />
            <div className="productlistinmain">
                <div className="prductlisting_main1">
                    {Data_list_1}
                </div>
            </div>
        </>
    )
}
