export const metaldata = [
    {
        id: 1001,
        title: "Cast Iron Scrap / cast iron scrap",
        brands: "Metals",
        types: "Iron",
        type2: "",
        minqty: 50,
        price: 600,
        maxprice: 600,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/01/01.jpg",
        images: [
            "../assets/product/metals/01/01.jpg",
            "../assets/product/metals/01/02.jpg",
            "../assets/product/metals/01/03.jpg",
        ],
        tags: [
            "HMS Scrap /Heavy Melting Scrap (HMS1 / HMS2) /HMS1 HMS 1&2 Shredded HMS Bundle  Steel Scrap",
            "Backed by huge industry knowledge, we are presenting a supreme quality range of HMS Scrap (1 & 2) to our highly valued consumers. These range of HMS Scrap  are highly acclaimed for its high durability, tensile strength and exhibits superior resistant to adverse conditions. We test each and every product before release by various industrial parameters by our quality analyst. Client purchase these at reasonable rate..",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "CAST IRON",
            "Germany",

        ],

    },
    {
        id: 1003,
        title: "China Factory scrap aluminum wire",
        brands: "Metals",
        types: "Aluminum",
        type2: "",
        minqty: 50,
        price: 3000,
        maxprice: 3600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/metals/03/01.jpg",
        images: [
            "../assets/product/metals/03/01.jpg",
            "../assets/product/metals/03/02.jpg",
            "../assets/product/metals/03/03.jpg",
        ],
        tags: [
            "Tensile strengt >=350",
            "Al(Min): 93.09",
            "Elongation：>= 30",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "haohu",
            "China",

        ],

    },
    {
        id: 1005,
        title: "Best Deals Stainless-steel Recycled Metal",
        brands: "Metals",
        types: "Copper, Iron, Steel",
        type2: "",
        minqty: 50,
        price: 1770,
        maxprice: 1950,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/05/01.jpg",
        images: [
            "../assets/product/metals/05/01.jpg",
            "../assets/product/metals/05/02.jpg",
            "../assets/product/metals/05/03.jpg",
        ],
        tags: [
            "elbow, tee, pipe nipple, bushing, coupling, insert, boss, cross, plug, hex nipple, weldolet, threadolet, sockolet, socket, cap, cross, flageolet, union, swage nipple, etc.",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Trdae405",
            "United States",

        ],

    },
    {
        id: 1007,
        title: "Aluminium alloy drawn round wires",
        brands: "Metals",
        types: "Aluminium",
        type2: "",
        minqty: 50,
        price: 3300,
        maxprice: 4200,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/07/01.jpg",
        images: [
            "../assets/product/metals/07/01.jpg",
            "../assets/product/metals/07/02.jpg",
            "../assets/product/metals/07/03.jpg",
        ],
        tags: [
            "Tensile strength：>=350",
            "Al(Min): 93.09",
            "Elongation：>= 30",
            "Chemical Composition(%)",
            "Si 0.04 Zn 0.02",
            "Cu 0.01 Ga 0.01",
            "Mg 0.01 Al 99.9",
            "Mn - Fe",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "haohu",
            "haohu",

        ],

    },
    {
        id: 1009,
        title: "99.9% Pure Wholesale",
        brands: "Metals",
        types: "Copper",
        type2: "",
        minqty: 50,
        price: 7950,
        maxprice: 12000,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/09/01.jpg",
        images: [
            "../assets/product/metals/09/01.jpg",
            "../assets/product/metals/09/02.jpg",
            "../assets/product/metals/09/03.jpg",
        ],
        tags: [
            "Super High quality Copper Wire Scrap 99.9%/Millberry Copper Scrap 99.99%",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "All Brands",
            "United States",

        ],

    },
    {
        id: 1011,
        title: "Excavating Machine",
        brands: "Metals",
        types: "Machine",
        type2: "",
        minqty: 50,
        price: 360000,
        maxprice: 420000,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/11/01.jpg",
        images: [
            "../assets/product/metals/11/01.jpg",
            "../assets/product/metals/11/02.jpg",
            "../assets/product/metals/11/03.jpg",
        ],
        tags: [
            "Scrap Metal Recycling Equipped with 125KW turbocharged engine, this type of grapple wheel excavator shows excellent performance in slope climbing, materials loading and so forth.",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "JINGGONG",
            "China",

        ],

    },
    {
        id: 1013,
        title: "Cheapest scrap for recycling Copper wire",
        brands: "Metals",
        types: "Copper",
        type2: "",
        minqty: 50,
        price: 1800,
        maxprice: 5130,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/13/01.png",
        images: [
            "../assets/product/metals/13/01.png",
            "../assets/product/metals/13/02.png",
            "../assets/product/metals/13/03.png",
        ],
        tags: [
            "Copper Wire Scrap, Cable, Cathode, Sheets & Metal Scraps",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Copper Wire Scrap",
            "Germany",

        ],

    },
    {
        id: 1015,
        title: "Scrap retention abgular button dies",
        brands: "Metals",
        types: "Aluminum, Metal, Stainless steel, Steel alloy",
        type2: "",
        minqty: 50,
        price: 30,
        maxprice: 30,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/15/01.jpg",
        images: [
            "../assets/product/metals/15/01.jpg",
            "../assets/product/metals/15/02.jpg",
            "../assets/product/metals/15/03.jpg",
        ],
        tags: [
            "Sodick AP250ls *2  Sodick AP200*2 oil cutting machine",
            "Sodick AQ560LXs * 2  Sodick AQ400Ls * 2  Wire Cutting Machine",
            "Waida PGX - 2500SP * 1  Waida PGX - 3000 * 3 optical profile grinding",
            "Sodick AD30Ls * 6  Die - sinker",
            "EDM / YASHIDA CGM - 200BS * 12    SurfaceGrinding Machine",
            "KGS - 250M * 1 Ladder grinding machine",
            "T1040A * 1  FX - 12S * 1 Centerless grinding",
            "M1308 * 1 M8982 * 1 Cylindrical grinding machines",
            "Dapeng PD - 50 * 1 laser engraving machines",
            "CATO  CTS - 650 * 2  CNC Lathes.",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "",
            "China",

        ],

    },
    {
        id: 1017,
        title: "Share to Wholesale scrap retention",
        brands: "Metals",
        types: "Aluminum, Metal, Stainless steel, Steel alloy",
        type2: "",
        minqty: 50,
        price: 30,
        maxprice: 30,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/metals/17/01.jpg",
        images: [
            "../assets/product/metals/17/01.jpg",
            "../assets/product/metals/17/02.jpg",
            "../assets/product/metals/17/03.jpg",
        ],
        tags: [
            "Sodick AP250ls *2  Sodick AP200*2 oil cutting machine",
            "Sodick AQ560LXs * 2  Sodick AQ400Ls * 2  Wire Cutting Machine",
            "Waida PGX - 2500SP * 1  Waida PGX - 3000 * 3 optical profile grinding",
            "Sodick AD30Ls * 6  Die - sinker",
            "EDM / YASHIDA CGM - 200BS * 12    SurfaceGrinding Machine",
            "KGS - 250M * 1 Ladder grinding machine",
            "T1040A * 1  FX - 12S * 1 Centerless grinding",
            "M1308 * 1 M8982 * 1 Cylindrical grinding machines",
            "Dapeng PD - 50 * 1 laser engraving machines",
            "CATO  CTS - 650 * 2  CNC Lathes.",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "CAST IRON",
            "Germany",

        ],

    },
    {
        id: 1019,
        title: "High Quality Bulk Copper Wire",
        brands: "Metals",
        types: "Copper",
        type2: "",
        minqty: 50,
        price: 4950,
        maxprice: 12000,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/19/01.jpg",
        images: [
            "../assets/product/metals/19/01.jpg",
            "../assets/product/metals/19/02.jpg",
            "../assets/product/metals/19/03.jpg",
        ],
        tags: [
            "Super High quality Copper Wire Scrap 99.9%/Millberry Copper Scrap 99.99%",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "All Brands",
            "United States",

        ],

    },
    {
        id: 1021,
        title: "High precision scrap retention carbide",
        brands: "Metals",
        types: "Aluminum",
        type2: "",
        minqty: 50,
        price: 30,
        maxprice: 30,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/21/01.jpg",
        images: [
            "../assets/product/metals/21/01.jpg",
            "../assets/product/metals/21/02.jpg",
            "../assets/product/metals/21/03.jpg",
        ],
        tags: [
            "Sodick AP250ls *2  Sodick AP200*2 oil cutting machine",
            "Sodick AQ560LXs * 2  Sodick AQ400Ls * 2  Wire Cutting Machine",
            "Waida PGX - 2500SP * 1  Waida PGX - 3000 * 3 optical profile grinding",
            "Sodick AD30Ls * 6  Die - sinker",
            'EDM / YASHIDA CGM - 200BS * 12    SurfaceGrinding Machine',
            "KGS - 250M * 1 Ladder grinding machine",
            "T1040A * 1  FX - 12S * 1 Centerless grinding",
            "M1308 * 1 M8982 * 1 Cylindrical grinding machines",
            "Dapeng PD - 50 * 1 laser engraving machines",
            "CATO  CTS - 650 * 2  CNC Lathes.",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "",
            "	China",

        ],

    },
    {
        id: 1023,
        title: "China Recycled Stainless Steel Plates",
        brands: "Metals",
        types: "Steel",
        type2: "",
        minqty: 50,
        price: 3600,
        maxprice: 4500,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/23/01.png",
        images: [
            "../assets/product/metals/23/01.png",
            "../assets/product/metals/23/02.png",
            "../assets/product/metals/23/03.png",
        ],
        tags: [
            "Stainless steel refers to steel that is not easy to rust in the atmosphere; it is relatively corrosion-resistant steel in specific acid, alkali, and salt conditions. Because stainless steel has a series of characteristics such as excellent corrosion resistance, formability, and strength and toughness in a wide temperature range, it has been widely used in petrochemical, atomic energy, light industry, textile, food, household appliances, etc. ",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "WT",
            "China",

        ],

    },
    {
        id: 1025,
        title: "Aluminium alloy drawn round wires",
        brands: "Metals",
        types: "Aluminium",
        type2: "",
        minqty: 50,
        price: 3300,
        maxprice: 4200,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/25/01.jpg",
        images: [
            "../assets/product/metals/25/01.jpg",
            "../assets/product/metals/25/02.jpg",
            "../assets/product/metals/25/03.jpg",
        ],
        tags: [

            "Tensile strength：>= 350",
            "Al(Min): 93.09",
            "Elongation：>= 30",
            "Chemical Composition(%)",
            "Si 0.04 Zn 0.02",
            "Cu 0.01 Ga 0.01",
            "Mg 0.01 Al 99.9",
            "Mn - Fe",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "haohu",
            "China",

        ],

    },
    {
        id: 1027,
        title: "Quality Aluminum Scrap Extrusion Aluminum",
        brands: "Metals",
        types: "Aluminum",
        type2: "",
        minqty: 50,
        price: 2100,
        maxprice: 3300,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/27/01.jpg",
        images: [
            "../assets/product/metals/27/01.jpg",
            "../assets/product/metals/27/02.jpg",
            "../assets/product/metals/27/03.jpg",
        ],
        tags: [
            "Key Specifications/Special Features:",

"Density: 2.702g / cm³",

            "Melting point: 660.37℃",

            "Boiling point: 2467.0℃",

            "Color: silver white",


        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Haohu",
            "China",

        ],

    },
    {
        id: 1029,
        title: "Sale Aluminum Metal",
        brands: "Metals",
        types: "Aluminum",
        type2: "",
        minqty: 50,
        price: 600,
        maxprice: 650,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/29/01.jpg",
        images: [
            "../assets/product/metals/29/01.jpg",
            "../assets/product/metals/29/02.jpg",
            "../assets/product/metals/29/03.jpg",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Aluminum Ingot",
            "Germany",

        ],

    },
    {
        id: 1031,
        title: "Factory price Recycled Silver",
        brands: "Metals",
        types: "Aluminum",
        type2: "",
        minqty: 50,
        price: 2700,
        maxprice: 3300,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/31/01.jpg",
        images: [
            "../assets/product/metals/31/01.jpg",
            "../assets/product/metals/31/02.jpg",
            "../assets/product/metals/31/03.png",
        ],
        tags: [
            "Product name:Cans scrap sold aluminum alloy    bulk stock Aluminum Used Beverage Scrap UBC Cans    Recycled Silver White Color", 

        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Haohu",
            "China",

        ],

    },
    
    {
        id: 1035,
        title: "PU foam scrap for sale",
        brands: "Metals",
        types: "Iron",
        type2: "",
        minqty: 50,
        price: 1.5,
        maxprice: 1.5,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/35/01.jpg",
        images: [
            "../assets/product/metals/35/01.jpg",
            "../assets/product/metals/35/02.jpg",
            "../assets/product/metals/35/03.jpg",
        ],
        tags: [
            "General Description : Polyurethane Trim Foam Off-Cuts. Trim Foam Off - Cuts typically produced in factories using foam as part of the manufacturing process. (Mainly from Converting factories).",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "000",
            "France",

        ],

    },
    {
        id: 1037,
        title: "high quality scrap copper wire",
        brands: "Metals",
        types: "Copper",
        type2: "",
        minqty: 50,
        price: 1050,
        maxprice: 1050,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/37/01.jpg",
        images: [
            "../assets/product/metals/37/01.jpg",
            "../assets/product/metals/37/02.jpg",
            "../assets/product/metals/37/03.jpg",
        ],
        tags: [
            "itemvalue Place of OriginChina Hebei Brand NameKuanghsen",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Copper Millberry",
            "United Kingdom",

        ],

    },
    {
        id: 1039,
        title: "HDPE blue regrind natural",
        brands: "Metals",
        types: "plastic",
        type2: "",
        minqty: 50,
        price: 600,
        maxprice: 650,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/39/01.jpg",
        images: [
            "../assets/product/metals/39/01.jpg",
            "../assets/product/metals/39/02.jpg",
            "../assets/product/metals/39/03.jpg",
        ],
        tags: [
            "ITEMSTANDARD Melt Flow Rate(g / 10min)0.1 Density(g / cm3)0.95 Power Ash % 0.01 Tensile Strength(MPa)22 Elongation At Break % 600 vironmental stress - cracking1500 We also have the following products: ",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "HDPE Drums Regrind",
            "United Kingdom",

        ],

    },
    {
        id: 1041,
        title: "Recycled Silver White for export",
        brands: "Metals",
        types: " Aluminum",
        type2: "",
        minqty: 50,
        price: 2400,
        maxprice: 3600,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/41/01.jpg",
        images: [
            "../assets/product/metals/41/01.jpg",
            "../assets/product/metals/41/02.jpg",
            "../assets/product/metals/41/03.jpg",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "haohu",
            "China",

        ],

    },
    {
        id: 1043,
        title: "recycling Copper wire",
        brands: "Metals",
        types: "Copper",
        type2: "",
        minqty: 50,
        price: 1800,
        maxprice: 5100,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/43/01.png",
        images: [
            "../assets/product/metals/43/01.png",
            "../assets/product/metals/43/02.png",
            "../assets/product/metals/43/03.png",
        ],
        tags: [
            "Copper Wire Scrap, Cable Scrap For Sale, Copper Cathode Scrap Specification: Name : Chemical Composition(%): ",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Copper Wire Scrap",
            "Germany",

        ],

    },
    {
        id: 1045,
        title: "Best SellingStainless Steel Scrap",
        brands: "Metals",
        types: "Steel",
        type2: "",
        minqty: 50,
        price: 82500,
        maxprice: 82500,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/45/01.jpg",
        images: [
            "../assets/product/metals/45/01.jpg",
            "../assets/product/metals/45/02.jpg",
            "../assets/product/metals/45/03.jpg",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Rama Metal",
            "Malaysia",

        ],

    },
    {
        id: 1047,
        title: "High Quality Stainless Steel Scrap",
        brands: "Metals",
        types: "Steel",
        type2: "",
        minqty: 50,
        price: 1050,
        maxprice: 1050,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/47/01.jpg",
        images: [
            "../assets/product/metals/47/01.jpg",
            "../assets/product/metals/47/02.jpg",
            "../assets/product/metals/47/03.jpg",
        ],
        tags: [
            "Stainless Steel: Grade 304 Grade 310Grade 316 400 Series",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "stainless steel scrap",
            "Thailand",

        ],

    },
    {
        id: 1049,
        title: "Melting Place Model Application",
        brands: "Metals",
        types: "Steel",
        type2: "",
        minqty: 50,
        price: 750,
        maxprice: 1050,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: false,
        thumbnail: "../assets/product/metals/49/01.jpg",
        images: [
            "../assets/product/metals/49/01.jpg",
            "../assets/product/metals/49/02.jpg",
            "../assets/product/metals/49/03.jpg",
        ],
        tags: [
            "HMS, which stands for Heavy Melting Scrap, is a classification used in the scrap metal industry to categorize ferrous (iron-containing) scrap metal that is suitable for recycling and melting down to produce new steel products. Here's a brief description of HMS:",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "Custom",
            "United States",

        ],

    },
    {
        id: 1051,
        title: "Factory Supply Electrolytic Copper Cathode",
        brands: "Metals",
        types: "Copper",
        type2: "",
        minqty: 50,
        price: 1200,
        maxprice: 1200,
        qty1: "50-250 Tonnes",
        qty2: ">250 Tonnes",
        recomended: true,
        thumbnail: "../assets/product/metals/51/01.jpg",
        images: [
            "../assets/product/metals/51/01.jpg",
            "../assets/product/metals/51/02.jpg",
            "../assets/product/metals/51/03.jpg",
        ],
        tags: [
            "Product NameHot selling standard model Cu-CATH-2 copper cathode MaterialCopper cathodeSize12X914X914mm GradeCu - CATH - 1 / Cu - CATH - 2 ertificateMTC SGS BV Thickness12mm or as required Purity99.95 % + Weight85kg / piece AlloyNon alloy",
        ],
        tabel_th: [
            "Brand Name",
            "origin",

        ],
        tabel_td: [
            "COPPER CATHODE",
            "United Kingdom",

        ],

    },

]

export default metaldata;
