import React, { useRef, useState } from 'react'
import ElectronicsDevicesParts from '../data/ElectronicsDevicesPart';
import ElectronicProductCard from '../ProductCard/ElectronicProductCard';
import BalankHaed from '../components/BalankHaed';

export default function Electronicsparts() {
    const arrowRef = useRef(null);


    const [selectedCategory, setSelectedCategory] = useState("all");
    const [items, setItems] = useState(ElectronicsDevicesParts);


    const filterItems = (types) => {
        if (types === "all") {
            return items;
        } else {
            return items.filter((item) => item.types === types);
        }
    };

    
    const filteredItems = filterItems("Electronics Parts");
    filteredItems.sort((a, b) => a.id - b.id);
    const Data_list_1 = filteredItems.map((item, i) => (
        <ElectronicProductCard item={item} key={i} />
    ));
    return (
        <>
            <BalankHaed />
            <div className="productlistinmain">
                <div className="prductlisting_main1">
                    {Data_list_1}
                </div>
            </div>
        </>
    )
}
