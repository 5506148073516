export const mobileandlaptops = [
    {
        id: 5001,
        title: "iPhone 14 Pro Max 256GB Deep Purple 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 4300,
        maxprice: 4499,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/01/01.webp",
        images: [
            "../assets/product/mobileandlaptops/01/01.webp",
            "../assets/product/mobileandlaptops/01/02.webp",
            "../assets/product/mobileandlaptops/01/03.webp",
            "../assets/product/mobileandlaptops/01/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "4323 MAh",
            "Nano + ESIM",
        ],


    },

    {
        id: 5004,
        title: "Vostro 3520 Professional And Personnel Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 1919,
        maxprice: 2329,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/04/01.webp",
        images: [
            "../assets/product/mobileandlaptops/04/01.webp",
            "../assets/product/mobileandlaptops/04/02.webp",
            "../assets/product/mobileandlaptops/04/03.webp",
            "../assets/product/mobileandlaptops/04/04.webp",
        ],
        tags: [
            "Latest 12th Generation Intel Core i7-1255U (12MB Cache, up t o 4.7 GHz, 10 cores)",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "32 GB",
            "2 TB",
            "Nvidia GeForce MX Series",
        ],


    },
    {
        id: 5015,
        title: "iPhone 14 Pro 256GB Deep Purple 5G ",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 3589,
        maxprice: 3899,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/15/01.webp",
        images: [
            "../assets/product/mobileandlaptops/15/01.webp",
            "../assets/product/mobileandlaptops/15/02.webp",
            "../assets/product/mobileandlaptops/15/03.webp",
            "../assets/product/mobileandlaptops/15/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "3200 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5018,
        title: "Vostro 3400 Laptop With 14-Inch Full HD Display",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 1369,
        maxprice: 1679,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/18/01.webp",
        images: [
            "../assets/product/mobileandlaptops/18/01.webp",
            "../assets/product/mobileandlaptops/18/02.webp",
            "../assets/product/mobileandlaptops/18/03.webp",
            "../assets/product/mobileandlaptops/18/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "16 GB",
            "512 TB",
            "Intel Iris/Iris Plus Graphics",
        ],


    },
    {
        id: 5030,
        title: "iPhone 14 Pro Max 256GB Deep Purple 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 3959,
        maxprice: 4269,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/30/01.webp",
        images: [
            "../assets/product/mobileandlaptops/30/01.webp",
            "../assets/product/mobileandlaptops/30/02.webp",
            "../assets/product/mobileandlaptops/30/03.webp",
            "../assets/product/mobileandlaptops/30/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "4323 MAh",
            "Nano + ESIM",
        ],


    },

    {
        id: 5033,
        title: "Professional & Business Series Inspiron 3501",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 989,
        maxprice: 199,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/33/01.webp",
        images: [
            "../assets/product/mobileandlaptops/33/01.webp",
            "../assets/product/mobileandlaptops/33/02.webp",
            "../assets/product/mobileandlaptops/33/03.webp",
            "../assets/product/mobileandlaptops/33/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "12 GB",
            "1256 GB",
            "Intel Xe Graphics",
        ],


    },


    {
        id: 5048,
        title: "Vostro 3520 Professional And Personnel Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 2239,
        maxprice: 1749,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/48/01.webp",
        images: [
            "../assets/product/mobileandlaptops/48/01.webp",
            "../assets/product/mobileandlaptops/48/02.webp",
            "../assets/product/mobileandlaptops/48/03.webp",
            "../assets/product/mobileandlaptops/48/04.webp",
        ],
        tags: [
            "Latest 12th Generation Intel Core i7-1255U (12MB Cache, up t o 4.7 GHz, 10 cores)",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "32 GB",
            "2 TB",
            "Nvidia GeForce MX Series",
        ],


    },
    {
        id: 5063,
        title: "Inspiron 7306 Convertible Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 2989,
        maxprice: 3299,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/63/01.webp",
        images: [
            "../assets/product/mobileandlaptops/63/01.webp",
            "../assets/product/mobileandlaptops/63/02.webp",
            "../assets/product/mobileandlaptops/63/03.webp",
            "../assets/product/mobileandlaptops/63/04.webp",
        ],
        tags: [
            "Take note: The black model of our 2-in-1 is fully equipped with a rechargeable active pen (PN771M) that is intuitively placed inside a built-in pen garage and will charge while stored",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "512 GB",
            "Intel",
        ],


    },
    {
        id: 5078,
        title: "Latest Model Vostro 3510 Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 2000,
        maxprice: 2209,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/78/01.webp",
        images: [
            "../assets/product/mobileandlaptops/78/01.webp",
            "../assets/product/mobileandlaptops/78/02.webp",
            "../assets/product/mobileandlaptops/78/03.webp",
            "../assets/product/mobileandlaptops/78/04.webp",
        ],
        tags: [
            "Travels easily wherever you go, features an ultra slim chassis at just 14.9mm and starts at 3.1lbs / 1.4kg",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "32 GB",
            "2 TB",
            "Nvidia GeForce MX Series",
        ],


    },
    {
        id: 5093,
        title: "Vostro 15 3500 Thin Business Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 1219,
        maxprice: 1519,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/93/01.webp",
        images: [
            "../assets/product/mobileandlaptops/93/01.webp",
            "../assets/product/mobileandlaptops/93/02.webp",
            "../assets/product/mobileandlaptops/93/03.webp",
            "../assets/product/mobileandlaptops/93/04.webp",
        ],
        tags: [
            "FHD panel offers more brightness and vivid color for an enhanced front-of-screen experience, and a 2-sided narrow border lets you see more with less distractions.",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "12 GB",
            "512 GB",
            "Intel Iris/Iris Plus Graphics",
        ],


    },
    {
        id: 5108,
        title: "Latest Model Vostro 3500 Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 1239,
        maxprice: 1549,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/108/01.webp",
        images: [
            "../assets/product/mobileandlaptops/108/01.webp",
            "../assets/product/mobileandlaptops/108/02.webp",
            "../assets/product/mobileandlaptops/108/03.webp",
            "../assets/product/mobileandlaptops/108/04.webp",
        ],
        tags: [
            "Travels easily wherever you go, features an ultra slim chassis at just 14.9mm and starts at 3.1lbs / 1.4kg",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "16 GB",
            "1280 GB",
            "Nvidia GeForce MX Series",
        ],


    },
    {
        id: 5123,
        title: "Vostro 3520 Professional And Personnel Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 1865,
        maxprice: 2075,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/123/01.webp",
        images: [
            "../assets/product/mobileandlaptops/123/01.webp",
            "../assets/product/mobileandlaptops/123/02.webp",
            "../assets/product/mobileandlaptops/123/03.webp",
            "../assets/product/mobileandlaptops/123/04.webp",
        ],
        tags: [
            "Latest 12th Generation Intel Core i7-1255U (12MB Cache, up t o 4.7 GHz, 10 cores)",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "512 GB",
            "Nvidia GeForce MX Series",
        ],


    },
    {
        id: 5138,
        title: "Vostro 3500 Business And Professional Laptop",
        brands: "DELL",
        types: "Laptop",
        type2: "DELL",
        minqty: 50,
        price: 1289,
        maxprice: 1499,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/138/01.webp",
        images: [
            "../assets/product/mobileandlaptops/138/01.webp",
            "../assets/product/mobileandlaptops/138/02.webp",
            "../assets/product/mobileandlaptops/138/03.webp",
            "../assets/product/mobileandlaptops/138/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "12 GB",
            "512 GB",
            "Intel UHD Graphics",
        ],
    },
    {
        id: 5045,
        title: "iPhone 14 Pro Max 256GB Space Black 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 3589,
        maxprice: 4099,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/45/01.webp",
        images: [
            "../assets/product/mobileandlaptops/45/01.webp",
            "../assets/product/mobileandlaptops/45/02.webp",
            "../assets/product/mobileandlaptops/45/03.webp",
            "../assets/product/mobileandlaptops/45/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "4323 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5075,
        title: "iPhone 13 128GB Blue 5G ",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2055,
        maxprice: 2200,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/75/01.webp",
        images: [
            "../assets/product/mobileandlaptops/75/01.webp",
            "../assets/product/mobileandlaptops/75/02.webp",
            "../assets/product/mobileandlaptops/75/03.webp",
            "../assets/product/mobileandlaptops/75/04.webp",
        ],
        tags: [
            "iPhone 13 features a cinema standard wide color gamut, displaying colors just as filmmakers intended. And with precise color accuracy, everything on the screen looks remarkably natural",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3240 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5090,
        title: "iPhone 13 128GB Pink 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 1939,
        maxprice: 2149,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/90/01.webp",
        images: [
            "../assets/product/mobileandlaptops/90/01.webp",
            "../assets/product/mobileandlaptops/90/02.webp",
            "../assets/product/mobileandlaptops/90/03.webp",
            "../assets/product/mobileandlaptops/90/04.webp",
        ],
        tags: [
            "iPhone 13 features a cinema standard wide color gamut, displaying colors just as filmmakers intended. And with precise color accuracy, everything on the screen looks remarkably natural",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3240 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5105,
        title: "iPhone 13 128GB Starlight 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 1982,
        maxprice: 2192,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/105/01.webp",
        images: [
            "../assets/product/mobileandlaptops/105/01.webp",
            "../assets/product/mobileandlaptops/105/02.webp",
            "../assets/product/mobileandlaptops/105/03.webp",
            "../assets/product/mobileandlaptops/105/04.webp",
        ],
        tags: [
            "iPhone 13 features a cinema standard wide color gamut, displaying colors just as filmmakers intended. And with precise color accuracy, everything on the screen looks remarkably natural",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3240 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5120,
        title: "iPhone 14 Pro Max 128GB Gold 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 3500,
        maxprice: 3700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/120/01.webp",
        images: [
            "../assets/product/mobileandlaptops/120/01.webp",
            "../assets/product/mobileandlaptops/120/02.webp",
            "../assets/product/mobileandlaptops/120/03.webp",
            "../assets/product/mobileandlaptops/120/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "4323 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5135,
        title: "iPhone 11 White 128GB 4G LTE 2020",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 1516,
        maxprice: 1626,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/135/01.webp",
        images: [
            "../assets/product/mobileandlaptops/135/01.webp",
            "../assets/product/mobileandlaptops/135/02.webp",
            "../assets/product/mobileandlaptops/135/03.webp",
            "../assets/product/mobileandlaptops/135/04.webp",
        ],
        tags: [
            "A13 Bionic chip third‑generation Neural Engine",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3110 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5150,
        title: "iPhone 14 Pro Max 256GB Deep Purple 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 4089,
        maxprice: 4299,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/150/01.webp",
        images: [
            "../assets/product/mobileandlaptops/150/01.webp",
            "../assets/product/mobileandlaptops/150/02.webp",
            "../assets/product/mobileandlaptops/150/03.webp",
            "../assets/product/mobileandlaptops/150/04.webp",
        ],
        tags: [
            "A14 Bionic is the first 5-nanometer chip in the industry, with advanced components literally atoms wide. Forty percent more transistors rev up speeds while increasing efficiency for great battery life. And a new ISP powers Dolby Vision recording — something no pro movie camera, let alone any other phone, can do.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "3687 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5165,
        title: "iPhone 14 Plus 128GB Midnight 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2600,
        maxprice: 2800,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/165/01.webp",
        images: [
            "../assets/product/mobileandlaptops/165/01.webp",
            "../assets/product/mobileandlaptops/165/02.webp",
            "../assets/product/mobileandlaptops/165/03.webp",
            "../assets/product/mobileandlaptops/165/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "4323 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5180,
        title: "iPhone 11 Pro Max",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2500,
        maxprice: 2600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/180/01.webp",
        images: [
            "../assets/product/mobileandlaptops/180/01.webp",
            "../assets/product/mobileandlaptops/180/02.webp",
            "../assets/product/mobileandlaptops/180/03.webp",
            "../assets/product/mobileandlaptops/180/04.webp",
        ],
        tags: [
            "iOS 13 OS ensures user-friendly interface along with desired customizations",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3969 MAh",
            "Nano + ESIM",
        ],


    },

    {
        id: 5195,
        title: "Certified Pre Owned - iPad 2018 (6th Generation)",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 400,
        maxprice: 420,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/195/01.webp",
        images: [
            "../assets/product/mobileandlaptops/195/01.webp",
            "../assets/product/mobileandlaptops/195/02.webp",
            "../assets/product/mobileandlaptops/195/03.webp",
            "../assets/product/mobileandlaptops/195/04.webp",
        ],
        tags: [
            "iPad is designed so that using it feels like second nature, even if you never have before. Multi-Touch gestures let you do things like browse photos, edit documents, and organize your files simply by touching the beautiful 9.7-inch Retina display",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "",
            "",
            "4323 MAh",
            "No SIM Card",
        ],


    },
    {
        id: 5210,
        title: "iPhone SE 2020 - Slim Packing (2nd-gen)",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 600,
        maxprice: 650,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/210/01.webp",
        images: [
            "../assets/product/mobileandlaptops/210/01.webp",
            "../assets/product/mobileandlaptops/210/02.webp",
            "../assets/product/mobileandlaptops/210/03.webp",
            "../assets/product/mobileandlaptops/210/04.webp",
        ],
        tags: [
            "Flaunts a dual rear camera with LED flash that helps capture flawless shots in low light conditions",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "5 - 7.9 MP",
            "3 GB",
            "1821 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5225,
        title: "iPhone 13 Pro 128GB Graphite 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2900,
        maxprice: 3100,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/225/01.webp",
        images: [
            "../assets/product/mobileandlaptops/225/01.webp",
            "../assets/product/mobileandlaptops/225/02.webp",
            "../assets/product/mobileandlaptops/225/03.webp",
            "../assets/product/mobileandlaptops/225/04.webp",
        ],
        tags: [
            "Macro Photography - With its redesigned lens and powerful autofocus system, the new Ultra-Wide camera can focus at just 2 cm — making even the smallest details seem epic. Transform a leaf into abstract art. Capture a caterpillar’s fuzz. Magnify a dewdrop. The beauty of tiny awaits",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "3095 MAh",
            "Nano + ESIM",
        ],


    },
   
    {
        id: 5270,
        title: "iPhone 13 256GB Midnight 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2600,
        maxprice: 2800,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/270/01.webp",
        images: [
            "../assets/product/mobileandlaptops/270/01.webp",
            "../assets/product/mobileandlaptops/270/02.webp",
            "../assets/product/mobileandlaptops/270/03.webp",
            "../assets/product/mobileandlaptops/270/04.webp",
        ],
        tags: [
            "iPhone 13 features a cinema standard wide color gamut, displaying colors just as filmmakers intended. And with precise color accuracy, everything on the screen looks remarkably natural",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3240 MAh",
            "Nano + ESIM",
        ],


    },

    {
        id: 5285,
        title: "iPhone 13 Mini 128GB Starlight 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2100,
        maxprice: 2200,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/285/01.webp",
        images: [
            "../assets/product/mobileandlaptops/285/01.webp",
            "../assets/product/mobileandlaptops/285/02.webp",
            "../assets/product/mobileandlaptops/285/03.webp",
            "../assets/product/mobileandlaptops/285/04.webp",
        ],
        tags: [
            "iPhone 13 features a cinema standard wide color gamut, displaying colors just as filmmakers intended. And with precise color accuracy, everything on the screen looks remarkably natural",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "2438 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5300,
        title: "iPhone 12 With Facetime 64GB",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 1700,
        maxprice: 1800,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/300/01.webp",
        images: [
            "../assets/product/mobileandlaptops/300/01.webp",
            "../assets/product/mobileandlaptops/300/02.webp",
            "../assets/product/mobileandlaptops/300/03.webp",
            "../assets/product/mobileandlaptops/300/04.webp",
        ],
        tags: [
            "Features ceramic shield with four times better drop performance",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "2815 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5315,
        title: "Certified Pre owned – iPhone XR",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 700,
        maxprice: 750,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/315/01.webp",
        images: [
            "../assets/product/mobileandlaptops/315/01.webp",
            "../assets/product/mobileandlaptops/315/02.webp",
            "../assets/product/mobileandlaptops/315/03.webp",
            "../assets/product/mobileandlaptops/315/04.webp",
        ],
        tags: [
            "An innovative backlight design allows the screen to stretch into the corners",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "5 - 7.9 MP",
            "3 GB",
            "2942 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5330,
        title: "iPhone SE 2020 - Slim Packing (2nd-gen)",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 900,
        maxprice: 950,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/330/01.webp",
        images: [
            "../assets/product/mobileandlaptops/330/01.webp",
            "../assets/product/mobileandlaptops/330/02.webp",
            "../assets/product/mobileandlaptops/330/03.webp",
            "../assets/product/mobileandlaptops/330/04.webp",
        ],
        tags: [
            "Flaunts a dual rear camera with LED flash that helps capture flawless shots in low light conditions",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "5 - 7.9 MP",
            "3 GB",
            "1821 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5345,
        title: "iPhone 12 Mini With Facetime",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 1700,
        maxprice: 1750,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/345/01.webp",
        images: [
            "../assets/product/mobileandlaptops/345/01.webp",
            "../assets/product/mobileandlaptops/345/02.webp",
            "../assets/product/mobileandlaptops/345/03.webp",
            "../assets/product/mobileandlaptops/345/04.webp",
        ],
        tags: [
            "The power of A14 Bionic brings the latest AR experiences to life. Explore ancient relics, dissect a frog — even try out a new couch with AR Quick Look.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "2227 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5360,
        title: "iPhone 14 256GB Yellow 5G With FaceTime",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2900,
        maxprice: 3000,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/360/01.webp",
        images: [
            "../assets/product/mobileandlaptops/360/01.webp",
            "../assets/product/mobileandlaptops/360/02.webp",
            "../assets/product/mobileandlaptops/360/03.webp",
            "../assets/product/mobileandlaptops/360/04.webp",
        ],
        tags: [
            "Facetime is available on the product & would be accessible in regions where facetime is permitted by telecom operators",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "6 GB",
            "3279 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5375,
        title: "iPhone 13 256GB Green 5G",
        brands: "Apple",
        types: "phones",
        type2: "Iphone",
        minqty: 50,
        price: 2500,
        maxprice: 2700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/375/01.webp",
        images: [
            "../assets/product/mobileandlaptops/375/01.webp",
            "../assets/product/mobileandlaptops/375/02.webp",
            "../assets/product/mobileandlaptops/375/03.webp",
            "../assets/product/mobileandlaptops/375/04.webp",
        ],
        tags: [
            "iPhone 13 features a cinema standard wide color gamut, displaying colors just as filmmakers intended. And with precise color accuracy, everything on the screen looks remarkably natural",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "IOS",
            "12 - 15.9 MP",
            "4 GB",
            "3240 MAh",
            "Nano + ESIM",
        ],


    },
    {
        id: 5003,
        title: "Galaxy S23 Ultra 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2900,
        maxprice: 3100,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/03/01.webp",
        images: [
            "../assets/product/mobileandlaptops/03/01.webp",
            "../assets/product/mobileandlaptops/03/02.webp",
            "../assets/product/mobileandlaptops/03/03.webp",
            "../assets/product/mobileandlaptops/03/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5017,
        title: "Galaxy S23 Ultra 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2850,
        maxprice: 31000,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/17/01.webp",
        images: [
            "../assets/product/mobileandlaptops/17/01.webp",
            "../assets/product/mobileandlaptops/17/02.webp",
            "../assets/product/mobileandlaptops/17/03.webp",
            "../assets/product/mobileandlaptops/17/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5032,
        title: "Galaxy S22 Ultra Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2400,
        maxprice: 2500,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/32/01.webp",
        images: [
            "../assets/product/mobileandlaptops/32/01.webp",
            "../assets/product/mobileandlaptops/32/02.webp",
            "../assets/product/mobileandlaptops/32/03.webp",
            "../assets/product/mobileandlaptops/32/04.webp",
        ],
        tags: [
            "Our fastest, most powerful chip ever. It’s an epic leap for smartphone technology",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5047,
        title: "Galaxy A54 Dual Sim Awesome Graphite 8GB",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 900,
        maxprice: 950,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/47/01.webp",
        images: [
            "../assets/product/mobileandlaptops/47/01.webp",
            "../assets/product/mobileandlaptops/47/02.webp",
            "../assets/product/mobileandlaptops/47/03.webp",
            "../assets/product/mobileandlaptops/47/04.webp",
        ],
        tags: [
            "Fingerprint (under display, optical), accelerometer, gyro, compass, barometer",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "8 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5062,
        title: "Galaxy S23 Ultra 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2700,
        maxprice: 2800,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/62/01.webp",
        images: [
            "../assets/product/mobileandlaptops/62/01.webp",
            "../assets/product/mobileandlaptops/62/02.webp",
            "../assets/product/mobileandlaptops/62/03.webp",
            "../assets/product/mobileandlaptops/62/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5077,
        title: "Galaxy A14 Dual Sim Black 4GB RAM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 250,
        maxprice: 260,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/77/01.webp",
        images: [
            "../assets/product/mobileandlaptops/77/01.webp",
            "../assets/product/mobileandlaptops/77/02.webp",
            "../assets/product/mobileandlaptops/77/03.webp",
            "../assets/product/mobileandlaptops/77/04.webp",
        ],
        tags: [
            "Triple camera",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "4 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5092,
        title: "Galaxy A14 Dual Sim Light Green 4GB RAM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 350,
        maxprice: 400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/92/01.webp",
        images: [
            "../assets/product/mobileandlaptops/92/01.webp",
            "../assets/product/mobileandlaptops/92/02.webp",
            "../assets/product/mobileandlaptops/92/03.webp",
            "../assets/product/mobileandlaptops/92/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "4 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5107,
        title: "Galaxy Z Flip 5 Dual SIM Graphite 8GB RAM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 1900,
        maxprice: 2000,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended:false,
        thumbnail: "../assets/product/mobileandlaptops/107/01.webp",
        images: [
            "../assets/product/mobileandlaptops/107/01.webp",
            "../assets/product/mobileandlaptops/107/02.webp",
            "../assets/product/mobileandlaptops/107/03.webp",
            "../assets/product/mobileandlaptops/107/04.webp",
        ],
        tags: [
            "Samsung Pay may not work",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "",
            "8 GB",
            "3700 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5122,
        title: "Galaxy S23 Ultra 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2500,
        maxprice: 2700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/122/01.webp",
        images: [
            "../assets/product/mobileandlaptops/122/01.webp",
            "../assets/product/mobileandlaptops/122/02.webp",
            "../assets/product/mobileandlaptops/122/03.webp",
            "../assets/product/mobileandlaptops/122/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5137,
        title: "Galaxy A34 Dual Sim Awesome",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 650,
        maxprice: 750,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/137/01.webp",
        images: [
            "../assets/product/mobileandlaptops/137/01.webp",
            "../assets/product/mobileandlaptops/137/02.webp",
            "../assets/product/mobileandlaptops/137/03.webp",
            "../assets/product/mobileandlaptops/137/04.webp",
        ],
        tags: [
            "Fingerprint (under display, optical), accelerometer, gyro, compass",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "8 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5152,
        title: "Galaxy M14 Dual SIM Blue 4GB RAM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 350,
        maxprice: 380,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/152/01.webp",
        images: [
            "../assets/product/mobileandlaptops/152/01.webp",
            "../assets/product/mobileandlaptops/152/02.webp",
            "../assets/product/mobileandlaptops/152/03.webp",
            "../assets/product/mobileandlaptops/152/04.webp",
        ],
        tags: [
            "The Galaxy M14 5G's 6.6-inch Infinity-V display gives you room to see and do more. With FHD+ technology and a 90Hz refresh rate, the content you see every day will look smoother and sharper.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "4 GB",
            "6000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5167,
        title: "Galaxy Z Flip 5 Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2400,
        maxprice: 2800,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/167/01.webp",
        images: [
            "../assets/product/mobileandlaptops/167/01.webp",
            "../assets/product/mobileandlaptops/167/02.webp",
            "../assets/product/mobileandlaptops/167/03.webp",
            "../assets/product/mobileandlaptops/167/04.webp",
        ],
        tags: [
            "Samsung Pay may not work",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "8 GB",
            "3700 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5182,
        title: "Galaxy Z Fold 5 Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 4000,
        maxprice: 4200,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/182/01.webp",
        images: [
            "../assets/product/mobileandlaptops/182/01.webp",
            "../assets/product/mobileandlaptops/182/02.webp",
            "../assets/product/mobileandlaptops/182/03.webp",
            "../assets/product/mobileandlaptops/182/04.webp",
        ],
        tags: [
            "Samsung Pay may not work",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "4235 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5197,
        title: "Galaxy S23 5G Dual SIM Phantom",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 1900,
        maxprice: 2300,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/197/01.webp",
        images: [
            "../assets/product/mobileandlaptops/197/01.webp",
            "../assets/product/mobileandlaptops/197/02.webp",
            "../assets/product/mobileandlaptops/197/03.webp",
            "../assets/product/mobileandlaptops/197/04.webp",
        ],
        tags: [
            "Unbox the change you want to see in the world. Crafted with recycled glass and PET film and coloured with natural dyes, each phone is tucked into a box made of recycled paper and paper-based protective film",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "8 GB",
            "3900 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5212,
        title: "Galaxy S23 plus 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2800,
        maxprice: 3150,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/212/01.webp",
        images: [
            "../assets/product/mobileandlaptops/212/01.webp",
            "../assets/product/mobileandlaptops/212/02.webp",
            "../assets/product/mobileandlaptops/212/03.webp",
            "../assets/product/mobileandlaptops/212/04.webp",
        ],
        tags: [
            "Unbox the change you want to see in the world. Crafted with recycled glass and PET film and coloured with natural dyes, each phone is tucked into a box made of recycled paper and paper-based protective film",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "8 GB",
            "4700 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5227,
        title: "Galaxy S23 Ultra 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 2800,
        maxprice: 3100,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/227/01.webp",
        images: [
            "../assets/product/mobileandlaptops/227/01.webp",
            "../assets/product/mobileandlaptops/227/02.webp",
            "../assets/product/mobileandlaptops/227/03.webp",
            "../assets/product/mobileandlaptops/227/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5242,
        title: "Galaxy S23 Ultra 5G Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 3200,
        maxprice: 3300,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/242/01.webp",
        images: [
            "../assets/product/mobileandlaptops/242/01.webp",
            "../assets/product/mobileandlaptops/242/02.webp",
            "../assets/product/mobileandlaptops/242/03.webp",
            "../assets/product/mobileandlaptops/242/04.webp",
        ],
        tags: [
            "The use of conscious materials makes this our most eco-friendly smartphone ever. Recycled glass and PET film accent the phone's exterior, and box made from recycled paper makes it planet-loving from the moment it hits your hands.",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "12 - 15.9 MP",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5257,
        title: "Galaxy S21 Ultra Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 1400,
        maxprice: 1500,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/257/01.webp",
        images: [
            "../assets/product/mobileandlaptops/257/01.webp",
            "../assets/product/mobileandlaptops/257/02.webp",
            "../assets/product/mobileandlaptops/257/03.webp",
            "../assets/product/mobileandlaptops/257/04.webp",
        ],
        tags: [
            "The highest resolution possible in a smartphone",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5272,
        title: "Galaxy A32 Dual SIM Awesome White",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price:600,
        maxprice: 700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/272/01.webp",
        images: [
            "../assets/product/mobileandlaptops/272/01.webp",
            "../assets/product/mobileandlaptops/272/02.webp",
            "../assets/product/mobileandlaptops/272/03.webp",
            "../assets/product/mobileandlaptops/272/04.webp",
        ],
        tags: [
            "64MP main camera captures every detail of your face in ultra-high resolution",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "6 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5287,
        title: "Galaxy S21 Plus Phantom Violet",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 950,
        maxprice: 1075,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/287/01.webp",
        images: [
            "../assets/product/mobileandlaptops/287/01.webp",
            "../assets/product/mobileandlaptops/287/02.webp",
            "../assets/product/mobileandlaptops/287/03.webp",
            "../assets/product/mobileandlaptops/287/04.webp",
        ],
        tags: [
            "Fingerprint (rear-mounted), accelerometer, gyro, proximity, compass",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "8 GB",
            "4800 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5302,
        title: "Galaxy Z Flip 3 5G Single SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 1200,
        maxprice: 1250,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/302/01.webp",
        images: [
            "../assets/product/mobileandlaptops/302/01.webp",
            "../assets/product/mobileandlaptops/302/02.webp",
            "../assets/product/mobileandlaptops/302/03.webp",
            "../assets/product/mobileandlaptops/302/04.webp",
        ],
        tags: [
            "Industry-leading display technology renders stunning colour reproduction and deep contrasts",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "8 GB",
            "3300 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5317,
        title: "Galaxy S21 Ultra Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 1350,
        maxprice: 1500,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/317/01.webp",
        images: [
            "../assets/product/mobileandlaptops/317/01.webp",
            "../assets/product/mobileandlaptops/317/02.webp",
            "../assets/product/mobileandlaptops/317/03.webp",
            "../assets/product/mobileandlaptops/317/04.webp",
        ],
        tags: [
            "The highest resolution possible in a smartphone",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "12 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5332,
        title: "Galaxy M32 Dual SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 550,
        maxprice: 600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/332/01.webp",
        images: [
            "../assets/product/mobileandlaptops/332/01.webp",
            "../assets/product/mobileandlaptops/332/02.webp",
            "../assets/product/mobileandlaptops/332/03.webp",
            "../assets/product/mobileandlaptops/332/04.webp",
        ],
        tags: [
            "Supports the installation of two connection cards and an external memory card into a separate port",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "6 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5347,
        title: "Galaxy S20 Plus Cosmic Grey",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 750,
        maxprice: 700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/347/01.webp",
        images: [
            "../assets/product/mobileandlaptops/347/01.webp",
            "../assets/product/mobileandlaptops/347/02.webp",
            "../assets/product/mobileandlaptops/347/03.webp",
            "../assets/product/mobileandlaptops/347/04.webp",
        ],
        tags: [
            "Comes with a beautiful 6.7 inch screen for crisp and clear video",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "12 GB",
            "4500 MAh",
            "Nano SIM",
        ],


    },


    {
        id: 5362,
        title: "Galaxy Z Flip 3 5G Single SIM",
        brands: "Samsung",
        types: "phones",
        type2: "Samsung",
        minqty: 50,
        price: 1100,
        maxprice: 1200,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/362/01.webp",
        images: [
            "../assets/product/mobileandlaptops/362/01.webp",
            "../assets/product/mobileandlaptops/362/02.webp",
            "../assets/product/mobileandlaptops/362/03.webp",
            "../assets/product/mobileandlaptops/362/04.webp",
        ],
        tags: [
            "Industry-leading display technology renders stunning colour reproduction and deep contrasts",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "8 GB",
            "3300 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5005,
        title: "Y9A Dual SIM Space Silver",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 350,
        maxprice: 400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/04/01.webp",
        images: [
            "../assets/product/mobileandlaptops/04/01.webp",
            "../assets/product/mobileandlaptops/04/02.webp",
            "../assets/product/mobileandlaptops/04/03.webp",
            "../assets/product/mobileandlaptops/04/04.webp",
        ],
        tags: [
            "Designed for quick charging speed and long battery life",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4300 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5019,
        title: "Y5P Dual SIM 2GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 100,
        maxprice: 140,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/19/01.webp",
        images: [
            "../assets/product/mobileandlaptops/19/01.webp",
            "../assets/product/mobileandlaptops/19/02.webp",
            "../assets/product/mobileandlaptops/19/03.webp",
            "../assets/product/mobileandlaptops/19/04.webp",
        ],
        tags: [
            "Offers 32 GB memory storage and a 3-slot design",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "2 GB",
            "3020 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5034,
        title: "Y5P Dual SIM 2GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 100,
        maxprice: 120,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/34/01.webp",
        images: [
            "../assets/product/mobileandlaptops/34/01.webp",
            "../assets/product/mobileandlaptops/34/02.webp",
            "../assets/product/mobileandlaptops/34/03.webp",
            "../assets/product/mobileandlaptops/34/04.webp",
        ],
        tags: [
            "HDR mode can provide more dynamic range and image details",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "2 GB",
            "3020 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5049,
        title: "P40 Pro Dual SIM 8GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 1500,
        maxprice: 1550,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/49/01.webp",
        images: [
            "../assets/product/mobileandlaptops/49/01.webp",
            "../assets/product/mobileandlaptops/49/02.webp",
            "../assets/product/mobileandlaptops/49/03.webp",
            "../assets/product/mobileandlaptops/49/04.webp",
        ],
        tags: [
            "Speak for yourself with the Ultra Vision Leica Quad Camera by capturing photos and videos anytime and anywhere you want",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "8 GB",
            "4200 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5064,
        title: "P40 Dual Sim 8GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 1000,
        maxprice: 1050,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/64/01.webp",
        images: [
            "../assets/product/mobileandlaptops/64/01.webp",
            "../assets/product/mobileandlaptops/64/02.webp",
            "../assets/product/mobileandlaptops/64/03.webp",
            "../assets/product/mobileandlaptops/64/04.webp",
        ],
        tags: [
            "Packed with a host of features, it scores high on the aspect of functionality. The fact that it ensures a seamless user-interface makes it worth the buy",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "",
            "8 GB",
            "3800 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5079,
        title: "Nova Y60 Dual SIM 4GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 450,
        maxprice: 500,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/79/01.webp",
        images: [
            "../assets/product/mobileandlaptops/79/01.webp",
            "../assets/product/mobileandlaptops/79/02.webp",
            "../assets/product/mobileandlaptops/79/03.webp",
            "../assets/product/mobileandlaptops/79/04.webp",
        ],
        tags: [
            "Active noise cancellation with dedicated mic ensures superior calling experience",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "4 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5094,
        title: "Y8s Dual SIM Midnight Black 6GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 200,
        maxprice: 250,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/94/01.webp",
        images: [
            "../assets/product/mobileandlaptops/94/01.webp",
            "../assets/product/mobileandlaptops/94/02.webp",
            "../assets/product/mobileandlaptops/94/03.webp",
            "../assets/product/mobileandlaptops/94/04.webp",
        ],
        tags: [
            "Features a nano-textured back with a sophisticated multilayered coating",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "4 GB",
            "4000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5109,
        title: "P50 Pocket Premium Dual Sim 12GB RAM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 2500,
        maxprice: 2650,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/109/01.webp",
        images: [
            "../assets/product/mobileandlaptops/109/01.webp",
            "../assets/product/mobileandlaptops/109/02.webp",
            "../assets/product/mobileandlaptops/109/03.webp",
            "../assets/product/mobileandlaptops/109/04.webp",
        ],
        tags: [
            "The phone comes with a 6.90-inch touchscreen primary display with a resolution of 2790x1188 pixels and an aspect ratio of 21:9. It also features a 1.04-inch touchscreen as its second display, with a resolution of 340x340 pixels at a pixel density of 328 pixels per inch (ppi)",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "12 GB",
            "4000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5124,
        title: "Y9s Dual SIM 6GB RAM 128GB 4G LTE",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 325,
        maxprice: 375,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/124/01.webp",
        images: [
            "../assets/product/mobileandlaptops/124/01.webp",
            "../assets/product/mobileandlaptops/124/02.webp",
            "../assets/product/mobileandlaptops/124/03.webp",
            "../assets/product/mobileandlaptops/124/04.webp",
        ],
        tags: [
            "Built-in Kirin 710F octa-core chipset effectively enhance the running performance",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "6 GB",
            "4000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5139,
        title: "P40 Lite Dual SIM 6GB RAM 128GB 5G LTE",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 440,
        maxprice: 480,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/139/01.webp",
        images: [
            "../assets/product/mobileandlaptops/139/01.webp",
            "../assets/product/mobileandlaptops/139/02.webp",
            "../assets/product/mobileandlaptops/139/03.webp",
            "../assets/product/mobileandlaptops/139/04.webp",
        ],
        tags: [
            "6.5 Inch, LTPS IPS LCD, HDR10, 1080 X 2400 Pixels",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "",
            "6 GB",
            "4000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5154,
        title: "Y9A Dual SIM 8GB RAM 128GB 4G LTE",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 500,
        maxprice: 550,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/154/01.webp",
        images: [
            "../assets/product/mobileandlaptops/154/01.webp",
            "../assets/product/mobileandlaptops/154/02.webp",
            "../assets/product/mobileandlaptops/154/03.webp",
            "../assets/product/mobileandlaptops/154/04.webp",
        ],
        tags: [
            "48MP main camera captures every detail of your face in ultra-high resolution",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4300 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5169,
        title: "P40 Lite Dual SIM 6GB RAM 128GB",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 350,
        maxprice: 400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/169/01.webp",
        images: [
            "../assets/product/mobileandlaptops/169/01.webp",
            "../assets/product/mobileandlaptops/169/02.webp",
            "../assets/product/mobileandlaptops/169/03.webp",
            "../assets/product/mobileandlaptops/169/04.webp",
        ],
        tags: [
            "Cinematic portraits with the bokeh lens, then switch to the macro lens and snap super-detailed nature shots",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "6 GB",
            "4200 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5184,
        title: "Cinematic portraits with the bokeh lens",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 500,
        maxprice: 525,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/184/01.webp",
        images: [
            "../assets/product/mobileandlaptops/184/01.webp",
            "../assets/product/mobileandlaptops/184/02.webp",
            "../assets/product/mobileandlaptops/184/03.webp",
            "../assets/product/mobileandlaptops/184/04.webp",
        ],
        tags: [
            "Profound 8GB RAM assures immense multitasking",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4300 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5199,
        title: "P50 Pro Dual SIM Golden Black",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 4400,
        maxprice: 4800,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/199/01.webp",
        images: [
            "../assets/product/mobileandlaptops/199/01.webp",
            "../assets/product/mobileandlaptops/199/02.webp",
            "../assets/product/mobileandlaptops/199/03.webp",
            "../assets/product/mobileandlaptops/199/04.webp",
        ],
        tags: [
            "50 W Wireless HUAWEI SuperCharge",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4360 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5214,
        title: "Y9A Dual SIM Sakura Pink",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 540,
        maxprice: 600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/214/01.webp",
        images: [
            "../assets/product/mobileandlaptops/214/01.webp",
            "../assets/product/mobileandlaptops/214/02.webp",
            "../assets/product/mobileandlaptops/214/03.webp",
            "../assets/product/mobileandlaptops/214/04.webp",
        ],
        tags: [
            "Screen display resolution of 1080 x 2400 pixels which delivers bright, crisp visuals",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4200 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5229,
        title: "Nova 9 Dual SIM Black",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 1650,
        maxprice: 1700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/229/01.webp",
        images: [
            "../assets/product/mobileandlaptops/229/01.webp",
            "../assets/product/mobileandlaptops/229/02.webp",
            "../assets/product/mobileandlaptops/229/03.webp",
            "../assets/product/mobileandlaptops/229/04.webp",
        ],
        tags: [
            "Ultra-Thin Design: Admire the double-coated finish of HUAWEI nova 9 as you take hold of the easy-grip, ultra-thin, lightweight 175g body* And get inspired to infinite possibilities via the iconic star orbit ring, which brings even more eye-catching beauty to every moment",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "8 GB",
            "4200 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5244,
        title: "Y9A Dual SIM Midnight Black",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 400,
        maxprice: 440,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/244/01.webp",
        images: [
            "../assets/product/mobileandlaptops/244/01.webp",
            "../assets/product/mobileandlaptops/244/02.webp",
            "../assets/product/mobileandlaptops/244/03.webp",
            "../assets/product/mobileandlaptops/244/04.webp",
        ],
        tags: [
            "Display resolution 1080 x 2400 pixels",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4200 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5259,
        title: "Nova 8I Dual SIM Starry Black",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 920,
        maxprice: 960,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/259/01.webp",
        images: [
            "../assets/product/mobileandlaptops/259/01.webp",
            "../assets/product/mobileandlaptops/259/02.webp",
            "../assets/product/mobileandlaptops/259/03.webp",
            "../assets/product/mobileandlaptops/259/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4300 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5274,
        title: "Y9A Dual SIM Sakura Pink",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 620,
        maxprice: 675,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/274/01.webp",
        images: [
            "../assets/product/mobileandlaptops/274/01.webp",
            "../assets/product/mobileandlaptops/274/02.webp",
            "../assets/product/mobileandlaptops/274/03.webp",
            "../assets/product/mobileandlaptops/274/04.webp",
        ],
        tags: [
            "Screen display resolution of 1080 x 2400 pixels which delivers bright, crisp visuals",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4200 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5289,
        title: "Y6 Prime 2019 Dual SIM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 700,
        maxprice: 750,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/289/01.webp",
        images: [
            "../assets/product/mobileandlaptops/289/01.webp",
            "../assets/product/mobileandlaptops/289/02.webp",
            "../assets/product/mobileandlaptops/289/03.webp",
            "../assets/product/mobileandlaptops/289/04.webp",
        ],
        tags: [
            "Comes with a beautiful 6.09 inch screen for crisp and clear video",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "3 GB",
            "3020 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5304,
        title: "Y9A Dual SIM Space Silver",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 400,
        maxprice: 450,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/04/01.webp",
        images: [
            "../assets/product/mobileandlaptops/04/01.webp",
            "../assets/product/mobileandlaptops/04/02.webp",
            "../assets/product/mobileandlaptops/04/03.webp",
            "../assets/product/mobileandlaptops/04/04.webp",
        ],
        tags: [
            "Designed for quick charging speed and long battery life",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4300 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5319,
        title: "Refurbished - Y5P Dual SIM",
        brands: "HUAWEI",
        types: "phones",
        type2: "HUAWEI",
        minqty: 50,
        price: 540,
        maxprice: 600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/319/01.webp",
        images: [
            "../assets/product/mobileandlaptops/319/01.webp",
            "../assets/product/mobileandlaptops/319/02.webp",
            "../assets/product/mobileandlaptops/319/03.webp",
            "../assets/product/mobileandlaptops/319/04.webp",
        ],
        tags: [
            "8MP main Camera captures every detail of your face in high resolution",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "8 GB",
            "3020 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5007,
        title: "Redmi 9A Dual Sim Clear Blue",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 240,
        maxprice: 280,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/07/01.webp",
        images: [
            "../assets/product/mobileandlaptops/07/01.webp",
            "../assets/product/mobileandlaptops/07/02.webp",
            "../assets/product/mobileandlaptops/07/03.webp",
            "../assets/product/mobileandlaptops/07/04.webp",
        ],
        tags: [
            "Aura design - An experience that surrounds you with the concentric design that delivers an additional grip and overall practicality",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "4 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5021,
        title: "12X Dual Sim Grey 8GB RAM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 1400,
        maxprice: 1450,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/21/01.webp",
        images: [
            "../assets/product/mobileandlaptops/21/01.webp",
            "../assets/product/mobileandlaptops/21/02.webp",
            "../assets/product/mobileandlaptops/21/03.webp",
            "../assets/product/mobileandlaptops/21/04.webp",
        ],
        tags: [
            "Xiaomi 12X 5G is ready to offer a seamless multitasking experience with its powerful chipset backed by 8GB RAM",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "32 MP & Above",
            "8 GB",
            "4500 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5036,
        title: "Redmi 9A Dual SIM 2GB RAM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 300,
        maxprice: 325,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false
        ,
        thumbnail: "../assets/product/mobileandlaptops/36/01.webp",
        images: [
            "../assets/product/mobileandlaptops/36/01.webp",
            "../assets/product/mobileandlaptops/36/02.webp",
            "../assets/product/mobileandlaptops/36/03.webp",
            "../assets/product/mobileandlaptops/36/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "2 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5051,
        title: "Redmi Note 10 5G Dual SIM 6GB RAM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 600,
        maxprice: 650,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/51/01.webp",
        images: [
            "../assets/product/mobileandlaptops/51/01.webp",
            "../assets/product/mobileandlaptops/51/02.webp",
            "../assets/product/mobileandlaptops/51/03.webp",
            "../assets/product/mobileandlaptops/51/04.webp",
        ],
        tags: [
            "6.5 DotDisplay",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "6 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5066,
        title: "11 Lite Dual SIM 8GB RAM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 950,
        maxprice: 1000,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/66/01.webp",
        images: [
            "../assets/product/mobileandlaptops/66/01.webp",
            "../assets/product/mobileandlaptops/66/02.webp",
            "../assets/product/mobileandlaptops/66/03.webp",
            "../assets/product/mobileandlaptops/66/04.webp",
        ],
        tags: [
            "Ultra-light weight |Ultra-comfortable grip | 6.81mm Slim",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "8 GB",
            "4250 MAh",
            "Nano SIM",
        ],


    },

    {
        id: 5081,
        title: "11 Lite Dual SIM Bubblegum Blue",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 850,
        maxprice: 900,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/81/01.webp",
        images: [
            "../assets/product/mobileandlaptops/81/01.webp",
            "../assets/product/mobileandlaptops/81/02.webp",
            "../assets/product/mobileandlaptops/81/03.webp",
            "../assets/product/mobileandlaptops/81/04.webp",
        ],
        tags: [
            "Ultra-light weight |Ultra-comfortable grip | 6.81mm Slim",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "16 - 31.9 MP",
            "6 GB",
            "4250 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5096,
        title: "Redmi 9A Dual SIM Granite Grey",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 200,
        maxprice: 250,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/96/01.webp",
        images: [
            "../assets/product/mobileandlaptops/96/01.webp",
            "../assets/product/mobileandlaptops/96/02.webp",
            "../assets/product/mobileandlaptops/96/03.webp",
            "../assets/product/mobileandlaptops/96/04.webp",
        ],
        tags: [
            "Redmi 9a brings a 16.58cm display with wider, more immersive aspect ratio of 20:9",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "2 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5111,
        title: "Redmi 9C Dual SIM 3GB RAM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 350,
        maxprice: 375,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/111/01.webp",
        images: [
            "../assets/product/mobileandlaptops/111/01.webp",
            "../assets/product/mobileandlaptops/111/02.webp",
            "../assets/product/mobileandlaptops/111/03.webp",
            "../assets/product/mobileandlaptops/111/04.webp",
        ],
        tags: [
            "6.53-inch display offers crystal clear hd visuals",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "3 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5126,
        title: "Poco M3 Pro Dual SIM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 500,
        maxprice: 550,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/126/01.webp",
        images: [
            "../assets/product/mobileandlaptops/126/01.webp",
            "../assets/product/mobileandlaptops/126/02.webp",
            "../assets/product/mobileandlaptops/126/03.webp",
            "../assets/product/mobileandlaptops/126/04.webp",
        ],
        tags: [
            "6.5 FHD + Dot Display",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "8 - 11.9 MP",
            "4 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5141,
        title: "Redmi 9A Dual SIM",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 200,
        maxprice: 250,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/141/01.webp",
        images: [
            "../assets/product/mobileandlaptops/141/01.webp",
            "../assets/product/mobileandlaptops/141/02.webp",
            "../assets/product/mobileandlaptops/141/03.webp",
            "../assets/product/mobileandlaptops/141/04.webp",
        ],
        tags: [
            "Redmi 9a brings a 16.58cm display with wider, more immersive aspect ratio of 20:9",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "2 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5156,
        title: "Redmi 9A Sport Dual Sim",
        brands: "Xiaomi",
        types: "phones",
        type2: "Xiaomi",
        minqty: 50,
        price: 350,
        maxprice: 400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/156/01.webp",
        images: [
            "../assets/product/mobileandlaptops/156/01.webp",
            "../assets/product/mobileandlaptops/156/02.webp",
            "../assets/product/mobileandlaptops/156/03.webp",
            "../assets/product/mobileandlaptops/156/04.webp",
        ],
        tags: [
            "The phone features a 6.53-inch HD+ LCD display with a waterdrop-style notch",
        ],
        tabel_th: [
            "Operating System",
            "Secondary Camera",
            "RAM Size",
            "Battery Size",
            "SIM Type",
        ],
        tabel_td: [
            "Android",
            "5 - 7.9 MP",
            "3 GB",
            "5000 MAh",
            "Nano SIM",
        ],


    },
    {
        id: 5006,
        title: "Macbook Air MGN63 13 Display",
        brands: "Apple",
        types: "Laptop",
        type2: "Apple",
        minqty: 50,
        price: 2600,
        maxprice: 2700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/06/01.webp",
        images: [
            "../assets/product/mobileandlaptops/06/01.webp",
            "../assets/product/mobileandlaptops/06/02.webp",
            "../assets/product/mobileandlaptops/06/03.webp",
            "../assets/product/mobileandlaptops/06/04.webp",
        ],
        tags: [
            "The Apple M1 chip gives the 13.3‑inch MacBook Air speed and power beyond belief",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "MacOS",
            "SSD",
            "8 GB",
            "256 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5020,
        title: "MacBook Air MQKQ3 15-Inch Display",
        brands: "Apple",
        types: "Laptop",
        type2: "Apple",
        minqty: 50,
        price: 4000,
        maxprice: 4400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/20/01.webp",
        images: [
            "../assets/product/mobileandlaptops/20/01.webp",
            "../assets/product/mobileandlaptops/20/02.webp",
            "../assets/product/mobileandlaptops/20/03.webp",
            "../assets/product/mobileandlaptops/20/04.webp",
        ],
        tags: [
            "15-inch Liquid Retina display with True Tone",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "MacOS",
            "SSD",
            "8 GB",
            "512 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5035,
        title: "MacBook Pro MPHF3 14-Inch Display",
        brands: "Apple",
        types: "Laptop",
        type2: "Apple",
        minqty: 50,
        price: 9600,
        maxprice: 9000,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/35/01.webp",
        images: [
            "../assets/product/mobileandlaptops/35/01.webp",
            "../assets/product/mobileandlaptops/35/02.webp",
            "../assets/product/mobileandlaptops/35/03.webp",
            "../assets/product/mobileandlaptops/35/04.webp",
        ],
        tags: [
            "Go all day thanks to the power-efficient design of the M2 Pro or M2 Max chip. And MacBook Pro delivers exceptional performance whether it’s running on battery or plugged in.",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "MacOS",
            "SSD",
            "16 GB",
            "1 TB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5050,
        title: "MacBook Pro MNWC3 16-Inch Display",
        brands: "Apple",
        types: "Laptop",
        type2: "Apple",
        minqty: 50,
        price: 8500,
        maxprice: 8000,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/50/01.webp",
        images: [
            "../assets/product/mobileandlaptops/50/01.webp",
            "../assets/product/mobileandlaptops/50/02.webp",
            "../assets/product/mobileandlaptops/50/03.webp",
            "../assets/product/mobileandlaptops/50/04.webp",
        ],
        tags: [
            "Go all day thanks to the power-efficient design of the M2 Pro or M2 Max chip. And MacBook Pro delivers exceptional performance whether it’s running on battery or plugged in.",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "MacOS",
            "SSD",
            "16 GB",
            "512 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5065,
        title: "MacBook Pro MK1E3 16-Inch Display",
        brands: "Apple",
        types: "Laptop",
        type2: "Apple",
        minqty: 50,
        price: 7400,
        maxprice: 7900,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/65/01.webp",
        images: [
            "../assets/product/mobileandlaptops/65/01.webp",
            "../assets/product/mobileandlaptops/65/02.webp",
            "../assets/product/mobileandlaptops/65/03.webp",
            "../assets/product/mobileandlaptops/65/04.webp",
        ],
        tags: [
            "The 16-Core GPU in the M1 Pro delivers the most advanced integrated graphics in a personal computer",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "MacOS",
            "SSD",
            "8 GB",
            "512 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5080,
        title: "Aspire 3 A315 Notebook With 15.6-Inch Display,",
        brands: "Acer",
        types: "Laptop",
        type2: "Acer",
        minqty: 50,
        price: 1200,
        maxprice: 1250,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/80/01.webp",
        images: [
            "../assets/product/mobileandlaptops/80/01.webp",
            "../assets/product/mobileandlaptops/80/02.webp",
            "../assets/product/mobileandlaptops/80/03.webp",
            "../assets/product/mobileandlaptops/80/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "512 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5095,
        title: "Nitro 5 AN515 Gaming Notebook 12th Gen",
        types: "Laptop",
        type2: "Acer",
        minqty: 50,
        price: 4300,
        maxprice: 4400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/95/01.webp",
        images: [
            "../assets/product/mobileandlaptops/95/01.webp",
            "../assets/product/mobileandlaptops/95/02.webp",
            "../assets/product/mobileandlaptops/95/03.webp",
            "../assets/product/mobileandlaptops/95/04.webp",
        ],
        tags: [
            "15.6 Inch FHD IPS 144Hz slim bezel LED LCD with nvidia RTX 3050 graphics",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "16 GB",
            "512 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5110,
        title: "Aspire 5 Spin A5SP14-51MTN-51V9",
        brands: "Acer",
        types: "Laptop",
        type2: "Acer",
        minqty: 50,
        price: 2250,
        maxprice: 2300,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/110/01.webp",
        images: [
            "../assets/product/mobileandlaptops/110/01.webp",
            "../assets/product/mobileandlaptops/110/02.webp",
            "../assets/product/mobileandlaptops/110/03.webp",
            "../assets/product/mobileandlaptops/110/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "512 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5125,
        title: "Aspire 3 A315 Notebook",
        brands: "Acer",
        types: "Laptop",
        type2: "Acer",
        minqty: 50,
        price: 2000,
        maxprice: 2100,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/125/01.webp",
        images: [
            "../assets/product/mobileandlaptops/125/01.webp",
            "../assets/product/mobileandlaptops/125/02.webp",
            "../assets/product/mobileandlaptops/125/03.webp",
            "../assets/product/mobileandlaptops/125/04.webp",
        ],
        tags: [
            "Powerful Productivity - For better performance, the Aspire 3 uses the latest Intel Processors, and delivers enough power to see you through your workday. Work, play, or relax; do it all with powerful productivity",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "256 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5140,
        title: "Chromebook Spin 311 Laptop",
        brands: "Acer",
        types: "Laptop",
        type2: "Acer",
        minqty: 50,
        price: 1350,
        maxprice: 1400,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/140/01.webp",
        images: [
            "../assets/product/mobileandlaptops/140/01.webp",
            "../assets/product/mobileandlaptops/140/02.webp",
            "../assets/product/mobileandlaptops/140/03.webp",
            "../assets/product/mobileandlaptops/140/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Chrome OS",
            "SSD",
            "4 GB",
            "64 GB",
            "Integrated Graphics",
        ],


    },
    {
        id: 5002,
        title: "14s Laptop With 14-Inch Display",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 700,
        maxprice: 750,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/02/01.webp",
        images: [
            "../assets/product/mobileandlaptops/02/01.webp",
            "../assets/product/mobileandlaptops/02/02.webp",
            "../assets/product/mobileandlaptops/02/03.webp",
            "../assets/product/mobileandlaptops/02/04.webp",
        ],
        tags: [
            "Empower your business and do great things with the windows",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "4 GB",
            "256 GB",
            "AMD Radeon VII",
        ],


    },
    {
        id: 5016,
        title: "15-dy2795wm Laptop 15.6-Inch FHD Display",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 1650,
        maxprice: 1700,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/16/01.webp",
        images: [
            "../assets/product/mobileandlaptops/16/01.webp",
            "../assets/product/mobileandlaptops/16/02.webp",
            "../assets/product/mobileandlaptops/16/03.webp",
            "../assets/product/mobileandlaptops/16/04.webp",
        ],
        tags: [
            "Enjoy ultra-wide viewing angles and seamlessly perform multi-monitor set-ups with a 15.6-inch, Full HD, IPS, micro-edge, and anti-glare display. The Intel Iris Xe Graphics gives you a new level of performance with crisp, stunning visuals, plus the convenience of a thin and light laptop.",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "16 GB",
            "512 GB",
            "Intel Iris/Iris Plus Graphics",
        ],


    },

    {
        id: 5031,
        title: "2022 Newest 250 G8 Business Laptop",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 1000,
        maxprice: 1100,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/31/01.webp",
        images: [
            "../assets/product/mobileandlaptops/31/01.webp",
            "../assets/product/mobileandlaptops/31/02.webp",
            "../assets/product/mobileandlaptops/31/03.webp",
            "../assets/product/mobileandlaptops/31/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "256 GB",
            "Intel UHD Graphics",
        ],


    },

    {
        id: 5046,
        title: "Newest Slim 15-DY2093WM Professinal & Business Laptop",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 1850,
        maxprice: 1900,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/46/01.webp",
        images: [
            "../assets/product/mobileandlaptops/46/01.webp",
            "../assets/product/mobileandlaptops/46/02.webp",
            "../assets/product/mobileandlaptops/46/03.webp",
            "../assets/product/mobileandlaptops/46/04.webp",
        ],
        tags: [
            "Travels easily wherever you go, features an ultra slim chassis at just 14.9mm and starts at 3.1lbs / 1.4kg",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "32 GB",
            "1 TB",
            "Intel Iris/Iris Plus Graphics",
        ],


    },

    {
        id: 5061,
        title: "2023 Newest Slim Pavilion Touchscreen Laptop",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 2800,
        maxprice: 3200,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/61/01.webp",
        images: [
            "../assets/product/mobileandlaptops/61/01.webp",
            "../assets/product/mobileandlaptops/61/02.webp",
            "../assets/product/mobileandlaptops/61/03.webp",
            "../assets/product/mobileandlaptops/61/04.webp",
        ],
        tags: [
            "Latest 12th Generation Intel Core i7-1255U Processor(10 Cores: 2 Performance-Cores + 8 Efficient-Cores, 12 Threads, 12MB Smart Cache), Max turbo frequency up to 4.70 GHz with Intel Turbo Boost Technology",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "32 GB",
            "1 TB",
            "Nvidia GeForce MX Series",
        ],


    },

    {
        id: 5076,
        title: "Professional & Business Notebook PC",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 1450,
        maxprice: 1500,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/mobileandlaptops/76/01.webp",
        images: [
            "../assets/product/mobileandlaptops/76/01.webp",
            "../assets/product/mobileandlaptops/76/02.webp",
            "../assets/product/mobileandlaptops/76/03.webp",
            "../assets/product/mobileandlaptops/76/04.webp",
        ],
        tags: [
            "The HP 250 Laptop keeps up with mobile workstyles with a thin and light design. The beautiful display with its narrow border design and big screen-to-body-ratio provides ample space for work or streaming content.s",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "12 GB",
            "512 GB",
            "Intel UHD Graphics",
        ],


    },

    {
        id: 5091,
        title: "2023 Newest Slim Envy Convertible-2-In-1 Laptop",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 2500,
        maxprice: 2600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/91/01.webp",
        images: [
            "../assets/product/mobileandlaptops/91/01.webp",
            "../assets/product/mobileandlaptops/91/02.webp",
            "../assets/product/mobileandlaptops/91/03.webp",
            "../assets/product/mobileandlaptops/91/04.webp",
        ],
        tags: [
            "Keep all your conversations private with the help of an easy-to-activate mute button",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "1 TB",
            "Intel Iris/Iris Plus Graphics",
        ],


    },

    {
        id: 5106,
        title: "Laptop 15s-fq5050ne",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 1700,
        maxprice: 1600,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/106/01.webp",
        images: [
            "../assets/product/mobileandlaptops/106/01.webp",
            "../assets/product/mobileandlaptops/106/02.webp",
            "../assets/product/mobileandlaptops/106/03.webp",
            "../assets/product/mobileandlaptops/106/04.webp",
        ],
        tags: [
            "Do your thing, all day and wherever you prefer, thanks to the HP 15.6 Laptop PC with a lightweight design and long battery life",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "8 GB",
            "512 GB",
            "AMD Radeon VII",
        ],


    },

    {
        id: 5121,
        title: "Newest Slim 15-DY2097NR Professinal & Business Laptop",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 2679,
        maxprice: 2689,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/121/01.webp",
        images: [
            "../assets/product/mobileandlaptops/121/01.webp",
            "../assets/product/mobileandlaptops/121/02.webp",
            "../assets/product/mobileandlaptops/121/03.webp",
            "../assets/product/mobileandlaptops/121/04.webp",
        ],
        tags: [
            "Reduce power consumption and help lower costs with a low halogen, ENERGY STAR certified, EPEAT Gold registered display that meets TCO Certified Edge with 85% Post-Consumer Recycled plastics",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "32 GB",
            "1024 GB",
            "Intel Xe Graphics",
        ],


    },

    {
        id: 5136,
        title: "14s Laptop With 14-Inch Display, AMD-3020e Processor/4GB RAM/256GB SSD/AMD Radeon Graphics/Windows 10 With Laptop Bag + Wireless Mouse + Black BT Headphone English black",
        brands: "HP",
        types: "Laptop",
        type2: "HP",
        minqty: 50,
        price: 3489,
        maxprice: 3499,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/mobileandlaptops/136/01.webp",
        images: [
            "../assets/product/mobileandlaptops/136/01.webp",
            "../assets/product/mobileandlaptops/136/02.webp",
            "../assets/product/mobileandlaptops/136/03.webp",
            "../assets/product/mobileandlaptops/136/04.webp",
        ],
        tags: [
            "Travels easily wherever you go, features an ultra slim chassis at just 14.9mm and starts at 3.1lbs / 1.4kg",
        ],
        tabel_th: [
            "Operating System",
            "Storage Type",
            "RAM Size",
            "Internal Memory",
            "Graphics Memory Name",
        ],
        tabel_td: [
            "Windows",
            "SSD",
            "64 GB",
            "2 TB",
            "Intel Xe Graphics",
        ],


    },
 
]

export default mobileandlaptops;
