import React from 'react'
import "../CSS/fashionproductcard.css"
import { useNavigate } from 'react-router-dom';

export default function MobileProductCard(props) {
    const navigate = useNavigate();
    const { thumbnail, title, price, id } = props.item;
    return (
        <div className="fashoin_phone_productcard" role="button" onClick={() => navigate(`/mobileandlaptops/${id}`)}>
            <div className="fashoin_phone_productcard_main_img">
                <div className="fashoin_phone_productcard_main_img_inside">
                    <img src={thumbnail} alt="" />
                </div>
            </div>
            <div className="fashoin_phone_productcard_disc">
                <div className="fashoin_phone_productcard_disc_modelnumber">
                    <h6>#{id}</h6>
                </div>
                <div className="fashoin_phone_productcard_disc_price">
                    <h1>{price} AED</h1>
                </div>
                <div className="fashoin_phone_productcard_disc_title">
                    <h6>{title}</h6>
                </div>
            </div>
        </div>
    )
}
