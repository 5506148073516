import React, { useRef, useState } from 'react'
import metaldata from '../data/metal_data';
import MetalProductCard from '../ProductCard/MetalProductCard';
import BalankHaed from '../components/BalankHaed';

export default function Metals() {

    const arrowRef = useRef(null);


    const [selectedCategory, setSelectedCategory] = useState("all");
    const [items, setItems] = useState(metaldata);


    const filterItems = (brands) => {
        if (brands === "all") {
            return items;
        } else {
            return items.filter((item) => item.brands === brands);
        }
    };


    const filteredItems = filterItems("Metals");
    filteredItems.sort((a, b) => a.id - b.id);
    const Data_list_1 = filteredItems.map((item, i) => (
        <MetalProductCard item={item} key={i} />
    ));


    return (
        <>
            <BalankHaed />
            <div className="productlistinmain">
                <div className="prductlisting_main1">
                    {Data_list_1}
                </div>
            </div>
        </>
    );
}
