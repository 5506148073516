import React from 'react'
import LandingPage from '../LandingPage/LandingPage';
import Categories from '../Categories/Categories';
import Misson from '../Mission/Misson';
import AboutUs from '../About us/AboutUs';
import SliderLoading from '../Slider/SliderLoading';
import FoodRecommened from '../recommented/food_recommened';
import OurServies from '../Our Servies/OurServies';
import FashionRecommented from '../recommented/FashionRecomented';
import ElectronicsDevice from '../recommented/ElectronicsDevice';
import MetalRecommented from '../recommented/MetalRecommented';
import MobileRecomented from '../recommented/MobileRecomented';
import Cars from '../recommented/cars';
import Contactus from '../Contectus/Contactus';
import BalankHaed from './BalankHaed';

export default function Home() {
  return (
    <>
      <BalankHaed/>
      <LandingPage />
      <Categories />
      <Misson />
      <AboutUs />
      <OurServies />
      <FoodRecommened />
      <ElectronicsDevice />
      <FashionRecommented />
      <Contactus />
    </>
  )
}
