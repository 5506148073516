import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../components/Home';
import Product from '../Product/Product';
import Metals from '../Metals/Metals';
import Foods from '../Foods/Foods';
import FoodProduct from '../Product/FoodProduct';
import CarProduct from '../Product/CarProduct';
import FashionProduct from '../Product/Fashonproduct';
import Electronicsproduct from '../Product/Electronicsproduct';
import ScrollToTop from '../components/ScrollToTap';
import MobileandcomputerProduct from '../Product/MobileandcomputerProduct';
import MetalProduct from '../Product/MetalProduct';
import Navbar from '../Header/Navbar';
import Footer from '../components/Footer';
import BalankFooter from '../components/BalankFooter';
import Phonesandlaptops from '../phone/Phone';
import Car from '../Car/Car';
import FashionNew from '../Fashion/fashion';
import Electronicsparts from '../ElectronicsPart/Electronicsparts';


export default function RoutesFunction() {
    return (
        <Router>
            <ScrollToTop />
            <Navbar />
            <Routes >
                <Route path="/" element={<Home />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/foods/:id" element={<FoodProduct />} />
                <Route path="/metals/:id" element={<MetalProduct />} />
                <Route path="/electronic/:id" element={<Electronicsproduct />} />
                <Route path="/fashion/:id" element={<FashionProduct />} />
                <Route path="/mobileandlaptops/:id" element={<MobileandcomputerProduct />} />
                <Route path="/cars/:id" element={<CarProduct />} />
                <Route path="/foods" element={<Foods />} />
                <Route path="/mobileandlaptops" element={<Phonesandlaptops />} />
                <Route path="/metals" element={<Metals />} />
                <Route path="/cars" element={<Car />} />
                <Route path="/fashion" element={<FashionNew />} />
                <Route path="/electronic" element={<Electronicsparts />} />
            </Routes>
            <BalankFooter />
            <Footer />
        </Router>
    )
}
