export const fashiondata = [
    {
        id: 2001,
        title: "Classics Logo Hoodie Black",
        brands: "PUMA",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 170,
        maxprice: 185,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/01/01.avif",
        images: [
            "../assets/product/fashion/01/01.avif",
            "../assets/product/fashion/01/02.avif",
            "../assets/product/fashion/01/03.avif",
            "../assets/product/fashion/01/04.avif",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Prints",
        ],


    },
    {
        id: 2005,
        title: "Hyperglam Cropped Top",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 198,
        maxprice: 208,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/05/01.webp",
        images: [
            "../assets/product/fashion/05/01.webp",
            "../assets/product/fashion/05/02.webp",
            "../assets/product/fashion/05/03.webp",
            "../assets/product/fashion/05/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Prints",
        ],


    },
    {
        id: 2010,
        title: "3 Stripe Future Icons Marimekko Top",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 198,
        maxprice: 208,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/fashion/10/01.webp",
        images: [
            "../assets/product/fashion/10/01.webp",
            "../assets/product/fashion/10/02.webp",
            "../assets/product/fashion/10/03.webp",
            "../assets/product/fashion/10/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "High Neck",
            "Party",
            "White",
        ],


    },
    {
        id: 2015,
        title: "Aeroknit Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 136,
        maxprice: 146,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: false,
        thumbnail: "../assets/product/fashion/15/01.webp",
        images: [
            "../assets/product/fashion/15/01.webp",
            "../assets/product/fashion/15/02.webp",
            "../assets/product/fashion/15/03.webp",
            "../assets/product/fashion/15/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Sport",
            "Black",
        ],


    },
    {
        id: 2020,
        title: "Agravic Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 135,
        maxprice: 145,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/20/01.webp",
        images: [
            "../assets/product/fashion/20/01.webp",
            "../assets/product/fashion/20/02.webp",
            "../assets/product/fashion/20/03.webp",
            "../assets/product/fashion/20/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Green",
        ],


    },
    {
        id: 2025,
        title: "3 Stripe Future Icons High Neck Top",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 162,
        maxprice: 172,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/25/01.webp",
        images: [
            "../assets/product/fashion/25/01.webp",
            "../assets/product/fashion/25/02.webp",
            "../assets/product/fashion/25/03.webp",
            "../assets/product/fashion/25/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "	High Neck",
            "Party",
            "Pink",
        ],


    },
    {
        id: 2030,
        title: "Agravic Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 135,
        maxprice: 145,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/30/01.webp",
        images: [
            "../assets/product/fashion/30/01.webp",
            "../assets/product/fashion/30/02.webp",
            "../assets/product/fashion/30/03.webp",
            "../assets/product/fashion/30/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Label"
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Black",
        ],


    },
    {
        id: 2035,
        title: "Lounge Ribbed Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 137,
        maxprice: 147,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/35/01.webp",
        images: [
            "../assets/product/fashion/35/01.webp",
            "../assets/product/fashion/35/02.webp",
            "../assets/product/fashion/35/03.webp",
            "../assets/product/fashion/35/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Black",
        ],



        id: 2040,
        title: "Sportswear Sherpa Vest Black",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 187,
        maxprice: 197,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/40/01.webp",
        images: [
            "../assets/product/fashion/40/01.webp",
            "../assets/product/fashion/40/02.webp",
            "../assets/product/fashion/40/03.webp",
            "../assets/product/fashion/40/04.webp",
        ],
        tags: [
            "Comfortable recycled polyester fleece fabric", "Snap button closure to front", "Dual side slip-in pockets", "High-low hem style", "adidas signature branding print"
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "High Neck",
            "Sport",
            "Black",
        ],


    },
    {
        id: 2045,
        title: "Farm Rio Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 118,
        maxprice: 128,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/45/01.webp",
        images: [
            "../assets/product/fashion/45/01.webp",
            "../assets/product/fashion/45/02.webp",
            "../assets/product/fashion/45/03.webp",
            "../assets/product/fashion/45/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Sport",
            "Purple",
        ],


    },
    {
        id: 2050,
        title: "Lounge Ribbed Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 137,
        maxprice: 147,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/50/01.webp",
        images: [
            "../assets/product/fashion/50/01.webp",
            "../assets/product/fashion/50/02.webp",
            "../assets/product/fashion/50/03.webp",
            "../assets/product/fashion/50/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Gold",
        ],


    },
    {
        id: 2055,
        title: "3 Stripe Train Icons Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 156,
        maxprice: 166,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/55/01.webp",
        images: [
            "../assets/product/fashion/55/01.webp",
            "../assets/product/fashion/55/02.webp",
            "../assets/product/fashion/55/03.webp",
            "../assets/product/fashion/55/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Sport",
            "Black",
        ],


    },
    {
        id: 2060,
        title: "Lounge Ribbed Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 137,
        maxprice: 147,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/60/01.webp",
        images: [
            "../assets/product/fashion/60/01.webp",
            "../assets/product/fashion/60/02.webp",
            "../assets/product/fashion/60/03.webp",
            "../assets/product/fashion/60/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Green",
        ],


    },
    {
        id: 2065,
        title: "3 Stripe Future Icon Mock Neck Top",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 162,
        maxprice: 172,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/65/01.webp",
        images: [
            "../assets/product/fashion/65/01.webp",
            "../assets/product/fashion/65/02.webp",
            "../assets/product/fashion/65/03.webp",
            "../assets/product/fashion/65/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Black",
        ],


    },
    {
        id: 2070,
        title: "Lounge Ribbed Tank",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 144,
        maxprice: 154,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/70/01.webp",
        images: [
            "../assets/product/fashion/70/01.webp",
            "../assets/product/fashion/70/02.webp",
            "../assets/product/fashion/70/03.webp",
            "../assets/product/fashion/70/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label."
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Grey",
        ],


    },
    {
        id: 2075,
        title: "Go-To Heathered Polo",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 172,
        maxprice: 182,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/75/01.webp",
        images: [
            "../assets/product/fashion/75/01.webp",
            "../assets/product/fashion/75/02.webp",
            "../assets/product/fashion/75/03.webp",
            "../assets/product/fashion/75/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Sport",
            "Pink",
        ],


    },
    {
        id: 2080,
        title: "Techfit Camo Print Crop Training T-Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 122,
        maxprice: 32,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/80/01.webp",
        images: [
            "../assets/product/fashion/80/01.webp",
            "../assets/product/fashion/80/02.webp",
            "../assets/product/fashion/80/03.webp",
            "../assets/product/fashion/80/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Sport",
            "Silver",
        ],


    },
    {
        id: 2085,
        title: "Techfit Camo Print Crop Training T-Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 122,
        maxprice: 132,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/85/01.webp",
        images: [
            "../assets/product/fashion/85/01.webp",
            "../assets/product/fashion/85/02.webp",
            "../assets/product/fashion/85/03.webp",
            "../assets/product/fashion/85/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Sport",
            "Black",
        ],


    },
    {
        id: 2090,
        title: "Clubhouse Classic Premium Tennis Polo Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 185,
        maxprice: 195,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/90/01.webp",
        images: [
            "../assets/product/fashion/90/01.webp",
            "../assets/product/fashion/90/02.webp",
            "../assets/product/fashion/90/03.webp",
            "../assets/product/fashion/90/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Collared Neck",
            "Sport",
            "Black",
        ],


    },
    {
        id: 2095,
        title: "Dance Bodysuit",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 199,
        maxprice: 209,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/95/01.webp",
        images: [
            "../assets/product/fashion/95/01.webp",
            "../assets/product/fashion/95/02.webp",
            "../assets/product/fashion/95/03.webp",
            "../assets/product/fashion/95/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Party",
            "Black",
        ],


    },
    {
        id: 2100,
        title: "Lounge Top",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 233,
        maxprice: 243,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/100/01.webp",
        images: [
            "../assets/product/fashion/100/01.webp",
            "../assets/product/fashion/100/02.webp",
            "../assets/product/fashion/100/03.webp",
            "../assets/product/fashion/100/04.webp",
        ],
        tags: [
            ""
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Collared Neck",
            "Party",
            "Green",
        ],


    },



    {
        id: 2119,
        title: "Modest Activewear women sweatpants",
        brands: "PUMA",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 116,
        maxprice: 126,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/119/01.webp",
        images: [
            "../assets/product/fashion/119/01.webp",
            "../assets/product/fashion/119/02.webp",
            "../assets/product/fashion/119/03.webp",
            "../assets/product/fashion/119/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Sport",
            "Purple",
        ],


    },
{
        id: 2018,
        title: "Flare Cropped Pants",
        brands: "PUMA",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 85,
        maxprice: 95,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/18/01.webp",
        images: [
            "../assets/product/fashion/18/01.webp",
            "../assets/product/fashion/18/02.webp",
            "../assets/product/fashion/18/03.webp",
            "../assets/product/fashion/18/04.webp",
        ],
        tags: [
            "Part of Mango's Committed collection",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Red",
        ],


    },
{
        id: 2036,
        title: "Bluv Quarter-Zip Sweatshirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 289,
        maxprice: 299,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/36/01.webp",
        images: [
            "../assets/product/fashion/36/01.webp",
            "../assets/product/fashion/36/02.webp",
            "../assets/product/fashion/36/03.webp",
            "../assets/product/fashion/36/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Black",
        ],


    },
{
        id: 2054,
        title: "Scribble Leggings",
        brands: "Adidas",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 205,
        maxprice: 215,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/54/01.webp",
        images: [
            "../assets/product/fashion/54/01.webp",
            "../assets/product/fashion/54/02.webp",
            "../assets/product/fashion/54/03.webp",
            "../assets/product/fashion/54/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Black",
        ],


    },
{
        id: 2072,
        title: "Linear Leggings",
        brands: "Adidas",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 62,
        maxprice: 72,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/72/01.webp",
        images: [
            "../assets/product/fashion/72/01.webp",
            "../assets/product/fashion/72/02.webp",
            "../assets/product/fashion/72/03.webp",
            "../assets/product/fashion/72/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Black",
        ],


    },
{
        id: 2089,
        title: "Wide Leg Pants",
        brands: "MANGO",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 289,
        maxprice: 299,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/89/01.webp",
        images: [
            "../assets/product/fashion/89/01.webp",
            "../assets/product/fashion/89/02.webp",
            "../assets/product/fashion/89/03.webp",
            "../assets/product/fashion/89/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Black",
        ],


    },
{
        id: 2108,
        title: "Wide Leg Pants",
        brands: "TOPSHOP",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 129,
        maxprice: 139,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/108/01.webp",
        images: [
            "../assets/product/fashion/108/01.webp",
            "../assets/product/fashion/108/02.webp",
            "../assets/product/fashion/108/03.webp",
            "../assets/product/fashion/108/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "White",
        ],


    },

{
        id: 2126,
        title: "Pocket Detail Cargo Pants",
        brands: "Cotton On",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 85,
        maxprice: 95,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/126/01.webp",
        images: [
            "../assets/product/fashion/126/01.webp",
            "../assets/product/fashion/126/02.webp",
            "../assets/product/fashion/126/03.webp",
            "../assets/product/fashion/126/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "White",
        ],


    },{
        id: 2144,
        title: "Wide Leg Pants",
        brands: "MANGO",
        types: "Womens",
        type2: "Pants",
        minqty: 50,
        price: 289,
        maxprice: 299,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/144/01.webp",
        images: [
            "../assets/product/fashion/144/01.webp",
            "../assets/product/fashion/144/02.webp",
            "../assets/product/fashion/144/03.webp",
            "../assets/product/fashion/144/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Black",
        ],


    },{
        id: 2162,
        title: "Legacy Tapered Pants Grey",
        brands: "Hummel",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 56,
        maxprice: 66,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/162/01.webp",
        images: [
            "../assets/product/fashion/162/01.webp",
            "../assets/product/fashion/162/02.webp",
            "../assets/product/fashion/162/03.webp",
            "../assets/product/fashion/162/04.webp",
        ],
        tags: [
            "Stretchable cotton blend fabric",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Street Wear",
            "Grey",
        ],


    },{
        id: 2180,
        title: "Yo Logo Season 7/8 Tights",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 138,
        maxprice: 148,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/180/01.webp",
        images: [
            "../assets/product/fashion/180/01.webp",
            "../assets/product/fashion/180/02.webp",
            "../assets/product/fashion/180/03.webp",
            "../assets/product/fashion/180/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Beige",
        ],


    },{
        id: 2198,
        title: "Paperbag Waist Cargo Trousers Beige",
        brands: "TOMMY JEANS",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 124,
        maxprice: 134,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/198/01.webp",
        images: [
            "../assets/product/fashion/198/01.webp",
            "../assets/product/fashion/198/02.webp",
            "../assets/product/fashion/198/03.webp",
            "../assets/product/fashion/198/04.webp",
        ],
        tags: [
            "Light and sustainable cotton blend fabric",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Beige",
        ],


    },{
        id: 2216,
        title: "Paperbag Waist Tapered Mom Pants",
        brands: "TOMMY JEANS",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 158,
        maxprice: 168,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/216/01.webp",
        images: [
            "../assets/product/fashion/216/01.webp",
            "../assets/product/fashion/216/02.webp",
            "../assets/product/fashion/216/03.webp",
            "../assets/product/fashion/216/04.webp",
        ],
        tags: [
            "Eco-friendly, comfortable organic cotton fabric",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Black",
        ],


    },{
        id: 2234,
        title: "High Waist Pants",
        brands: "MANGO",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 169,
        maxprice: 179,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/234/01.webp",
        images: [
            "../assets/product/fashion/234/01.webp",
            "../assets/product/fashion/234/02.webp",
            "../assets/product/fashion/234/03.webp",
            "../assets/product/fashion/234/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "White",
        ],


    },
    {
        id: 2252,
        title: "Logo Leggings",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 116,
        maxprice: 126,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/252/01.jpg",
        images: [
            "../assets/product/fashion/252/01.jpg",
            "../assets/product/fashion/252/02.webp",
            "../assets/product/fashion/252/03.webp",
            "../assets/product/fashion/252/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Multicolor",
        ],


    },{
        id: 2270,
        title: "Terrex Zupahike Hiking Pants Olive",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 211,
        maxprice: 221,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/270/01.webp",
        images: [
            "../assets/product/fashion/270/01.webp",
            "../assets/product/fashion/270/02.webp",
            "../assets/product/fashion/270/03.webp",
            "../assets/product/fashion/270/04.webp",
        ],
        tags: [
            "Four-way stretch nylon and polyester blend fabric",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Sport",
            "Olive",
        ],


    },{
        id: 2288,
        title: "Essential Legging",
        brands: "PUMA",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 65,
        maxprice: 75,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/288/01.webp",
        images: [
            "../assets/product/fashion/288/01.webp",
            "../assets/product/fashion/288/02.webp",
            "../assets/product/fashion/288/03.webp",
            "../assets/product/fashion/288/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Black",
        ],


    },{
        id: 2306,
        title: "Ruched Neck Detail Dress",
        brands: "Aerie",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 102,
        maxprice: 112,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/306/01.webp",
        images: [
            "../assets/product/fashion/306/01.webp",
            "../assets/product/fashion/306/02.webp",
            "../assets/product/fashion/306/03.webp",
            "../assets/product/fashion/306/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Red",
        ],


    },{
        id: 2324,
        title: "Active Leggings",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 236,
        maxprice: 246,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/324/01.webp",
        images: [
            "../assets/product/fashion/324/01.webp",
            "../assets/product/fashion/324/02.webp",
            "../assets/product/fashion/324/03.webp",
            "../assets/product/fashion/324/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Party",
        ],


    },{
        id: 2342,
        title: "Essentials Big Logo Boyfriend T-Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 139,
        maxprice: 149,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/342/01.webp",
        images: [
            "../assets/product/fashion/342/01.webp",
            "../assets/product/fashion/342/02.webp",
            "../assets/product/fashion/342/03.webp",
            "../assets/product/fashion/342/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Blue",
        ],


    },
{
        id: 2360,
        title: "Terrex Mountain Fun Graphic T-Shirt",
        brands: "Adidas",
        types: "Womens",
        type2: "Tops",
        minqty: 50,
        price: 81,
        maxprice: 91,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/360/01.webp",
        images: [
            "../assets/product/fashion/360/01.webp",
            "../assets/product/fashion/360/02.webp",
            "../assets/product/fashion/360/03.webp",
            "../assets/product/fashion/360/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neck line",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Round Neck",
            "Sport",
            "Purple",
        ],


    },
{
        id: 2002,
        title: "D4T Pants",
        brands: "Adidas",
        types: "Mens",
        type2: "Pants",
        minqty: 50,
        price: 289,
        maxprice: 299,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/02/01.webp",
        images: [
            "../assets/product/fashion/02/01.webp",
            "../assets/product/fashion/02/02.webp",
            "../assets/product/fashion/02/03.webp",
            "../assets/product/fashion/02/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Sport",
            "Black",
        ],


    },
{
        id: 2019,
        title: "Essentials Single Jersey Embroidered Small Logo T-Shirt",
        brands: "Adidas",
        types: "Mens",
        type2: "T-Shirts",
        minqty: 50,
        price: 91,
        maxprice: 101,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/19/01.webp",
        images: [
            "../assets/product/fashion/19/01.webp",
            "../assets/product/fashion/19/02.webp",
            "../assets/product/fashion/19/03.webp",
            "../assets/product/fashion/19/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Crew Neck",
            "Sport",
            "Black",
        ],


    },
{
        id: 2037,
        title: "Men’s Half Sleeve Casual T-shirt Round Neck Printed Cool Fashion Comfortable Soft Cotton Tees for Boys",
        brands: "Generic",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 77,
        maxprice: 87,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/37/01.webp",
        images: [
            "../assets/product/fashion/37/01.webp",
            "../assets/product/fashion/37/02.webp",
            "../assets/product/fashion/37/03.webp",
            "../assets/product/fashion/37/04.webp",
        ],
        tags: [
            "Fabric: High-quality cotton fabric for ultimate comfort Lightweight and breathable material, perfect for everyday wear. In addition to its luxurious feel, our cotton fabric is also lightweight and moisture-wicking,",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "White",
        ],


    },
{
        id: 2056,
        title: "From Macaw to Macau Shorts",
        brands: "Orabelle",
        types: "Mens",
        type2: "Shorts",
        minqty: 50,
        price: 206,
        maxprice: 216,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/56/01.webp",
        images: [
            "../assets/product/fashion/56/01.webp",
            "../assets/product/fashion/56/02.webp",
            "../assets/product/fashion/56/03.webp",
            "../assets/product/fashion/56/04.webp",
        ],
        tags: [
            "97% cotton 3% elastane",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Kentucky Blue",
        ],


    },
{
        id: 2073,
        title: "Watermelon Sparkler Shirt",
        brands: "Orabelle",
        types: "Mens",
        type2: "Shirt",
        minqty: 50,
        price: 169,
        maxprice: 179,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/73/01.webp",
        images: [
            "../assets/product/fashion/73/01.webp",
            "../assets/product/fashion/73/02.webp",
            "../assets/product/fashion/73/03.webp",
            "../assets/product/fashion/73/04.webp",
        ],
        tags: [
            "100% natural cotton",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "White",
        ],


    },
{
        id: 2091,
        title: "Tabela 23 Jersey",
        brands: "Adidas",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 74,
        maxprice: 84,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/91/01.webp",
        images: [
            "../assets/product/fashion/91/01.webp",
            "../assets/product/fashion/91/02.webp",
            "../assets/product/fashion/91/03.webp",
            "../assets/product/fashion/91/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Crew Neck",
            "Sport",
            "Blue",
        ],


    },
{
        id: 2109,
        title: "Chain Net Graphic T-Shirt",
        brands: "Adidas",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 60,
        maxprice: 70,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/109/01.webp",
        images: [
            "../assets/product/fashion/109/01.webp",
            "../assets/product/fashion/109/02.webp",
            "../assets/product/fashion/109/03.webp",
            "../assets/product/fashion/109/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
           "Crew Neck",
            "Sport",
            "Pink",
        ],


    },
{
        id: 2127,
        title: "I'm Vengeance Shorts",
        brands: "Orabelle",
        types: "Mens",
        type2: "Shorts",
        minqty: 50,
        price: 158,
        maxprice: 168,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/127/01.webp",
        images: [
            "../assets/product/fashion/127/01.webp",
            "../assets/product/fashion/127/02.webp",
            "../assets/product/fashion/127/03.webp",
            "../assets/product/fashion/127/04.webp",
        ],
        tags: [
            "97% cotton 3% elastane",
        ],
        tabel_th: [
            
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Matt Black",
        ],


    },
{
        id: 2145,
        title: "Essential Big Logo Fleece Hoodie",
        brands: "PUMA",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 139,
        maxprice: 149,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/145/01.webp",
        images: [
            "../assets/product/fashion/145/01.webp",
            "../assets/product/fashion/145/02.webp",
            "../assets/product/fashion/145/03.webp",
            "../assets/product/fashion/145/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
             "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
              "Hooded Neck",
            "Party",
            "Black",
        ],


    },
{
        id: 2163,
        title: "Refibra Logo Hoodie",
        brands: "Timberland",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 230,
        maxprice: 240,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/163/01.webp",
        images: [
            "../assets/product/fashion/163/01.webp",
            "../assets/product/fashion/163/02.webp",
            "../assets/product/fashion/163/03.webp",
            "../assets/product/fashion/163/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
              "Hooded Neck",
            "Party",
            "Brown",
        ],


    },
{
        id: 2181,
        title: "Future Icons Badge Of Sport Sweatpants",
        brands: "Adidas",
        types: "Mens",
        type2: "Shirt",
        minqty: 50,
        price: 154,
        maxprice: 164,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/181/01.webp",
        images: [
            "../assets/product/fashion/181/01.webp",
            "../assets/product/fashion/181/02.webp",
            "../assets/product/fashion/181/03.webp",
            "../assets/product/fashion/181/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Brown",
        ],


    },
{
        id: 2199,
        title: "Future Icons Badge Of Sport Sweatpants",
        brands: "Adidas",
        types: "Mens",
        type2: "Pants",
        minqty: 50,
        price: 134,
        maxprice: 144,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/199/01.webp",
        images: [
            "../assets/product/fashion/199/01.webp",
            "../assets/product/fashion/199/02.webp",
            "../assets/product/fashion/199/03.webp",
            "../assets/product/fashion/199/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Party",
            "Purple",
        ],


    },
{
        id: 2217,
        title: "1990 Logo Relaxed Fit Hoodie",
        brands: "JACK & JONES",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 89,
        maxprice: 99,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/217/01.webp",
        images: [
            "../assets/product/fashion/217/01.webp",
            "../assets/product/fashion/217/02.webp",
            "../assets/product/fashion/217/03.webp",
            "../assets/product/fashion/217/04.webp",
        ],
        tags: [
            "Warm poly-cotton blend fabric with fleece lining",
        ],
        tabel_th: [
             "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
           "Hooded Neck",
            "Casual",
            "Black",
        ],


    },
{
        id: 2235,
        title: "Essential Regular Fit Shirt",
        brands: "JACK & JONES",
        types: "Mens",
        type2: "Shirt",
        minqty: 50,
        price: 119,
        maxprice: 129,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/235/01.webp",
        images: [
            "../assets/product/fashion/235/01.webp",
            "../assets/product/fashion/235/02.webp",
            "../assets/product/fashion/235/03.webp",
            "../assets/product/fashion/235/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Collared Neck",
            "Casual",
            "Grey",
        ],


    },
{
        id: 2253,
        title: "Aeroready Yoga Base Hoodie",
        brands: "Adidas",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 134,
        maxprice: 144,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/253/01.webp",
        images: [
            "../assets/product/fashion/253/01.webp",
            "../assets/product/fashion/253/02.webp",
            "../assets/product/fashion/253/03.webp",
            "../assets/product/fashion/253/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
             "Hooded Neck",
            "Sport",
            "Brown",
        ],


    },
{
        id: 2271,
        title: "Train Fav Men Shorts",
        brands: "PUMA",
        types: "Mens",
        type2: "Shorts",
        minqty: 50,
        price: 79,
        maxprice: 89,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/271/01.webp",
        images: [
            "../assets/product/fashion/271/01.webp",
            "../assets/product/fashion/271/02.webp",
            "../assets/product/fashion/271/03.webp",
            "../assets/product/fashion/271/04.webp",
        ],
        tags: [
            "Wash According To Instructions On Care Label.",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Sport",
            "Black",
        ],


    },
{
        id: 2289,
        title: "Essential Slim Fit Shirt",
        brands: "JACK & JONES",
        types: "Mens",
        type2: "Shirt",
        minqty: 50,
        price: 57,
        maxprice: 67,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/289/01.webp",
        images: [
            "../assets/product/fashion/289/01.webp",
            "../assets/product/fashion/289/02.webp",
            "../assets/product/fashion/289/03.webp",
            "../assets/product/fashion/289/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
             "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Mandarin Neck",
            "Casual",
            "White",
        ],


    },
{
        id: 2307,
        title: "Washed Oversized Fit Sweatshirt",
        brands: "JACK & JONES",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 72,
        maxprice: 82,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/307/01.webp",
        images: [
            "../assets/product/fashion/307/01.webp",
            "../assets/product/fashion/307/02.webp",
            "../assets/product/fashion/307/03.webp",
            "../assets/product/fashion/307/04.webp",
        ],
        tags: [
            "Stretchable, rich cotton french terry fabric",
        ],
        tabel_th: [
              "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
             "Round Neck",
            "Casual",
            "Seal Brown",
        ],


    },
{
        id: 2325,
        title: "Woven Non-Hooded Tracksuit",
        brands: "Adidas",
        types: "Mens",
        type2: "Tracksuit",
        minqty: 50,
        price: 269,
        maxprice: 279,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/325/01.webp",
        images: [
            "../assets/product/fashion/325/01.webp",
            "../assets/product/fashion/325/02.webp",
            "../assets/product/fashion/325/03.webp",
            "../assets/product/fashion/325/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "High Neck",
            "Party",
            "Black",
        ],


    },
{
        id: 2343,
        title: "Essential Straight Fit Pants",
        brands: "JACK & JONES",
        types: "Mens",
        type2: "Pants",
        minqty: 50,
        price: 138,
        maxprice: 148,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/343/01.webp",
        images: [
            "../assets/product/fashion/343/01.webp",
            "../assets/product/fashion/343/02.webp",
            "../assets/product/fashion/343/03.webp",
            "../assets/product/fashion/343/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Casual",
            "Black",
        ],


    },
{
        id: 2361,
        title: "Essential Blazer",
        brands: "Mango Man",
        types: "Mens",
        type2: "Shirt",
        minqty: 50,
        price: 242,
        maxprice: 252,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/361/01.webp",
        images: [
            "../assets/product/fashion/361/01.webp",
            "../assets/product/fashion/361/02.webp",
            "../assets/product/fashion/361/03.webp",
            "../assets/product/fashion/361/04.webp",
        ],
        tags: [
            "",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Collared Neck",
            "Casual",
            "Navy",
        ],


    },
{
        id: 2379,
        title: "Wolf Printed Hoodie Blue/Yellow/Black",
        brands: "Lucky",
        types: "Mens",
        type2: "T-Shirt",
        minqty: 50,
        price: 59,
        maxprice: 69,
        qty1: "50-250 Pieces",
        qty2: ">250 Pieces",
        recomended: true,
        thumbnail: "../assets/product/fashion/379/01.webp",
        images: [
            "../assets/product/fashion/379/01.webp",
            "../assets/product/fashion/379/02.webp",
            "../assets/product/fashion/379/03.webp",
            "../assets/product/fashion/379/04.webp",
        ],
        tags: [
            "Made with highest quality soft fabric which renders utmost relaxation and comfort",
        ],
        tabel_th: [
            "Neckline",
            "Occasion",
            "Colour Name",
        ],
        tabel_td: [
            "Hooded Neck",
            "Casual",
            "Blue/Yellow/Black",
        ],


    },
   
]

export default fashiondata;
