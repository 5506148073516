import React from 'react'
import "../CSS/car_product_recommened.css"
import { useNavigate } from 'react-router-dom';

export default function CarProductCard(props) {
    const navigate = useNavigate();
    const { thumbnail, title, product_id, price, id } = props.item;
    return (
        <div className="car_productcard" role="button" onClick={() => navigate(`/cars/${id}`)}>
            <div className="car_main">
                <div className="car_img">
                    <div className="car_image_inner">
                        <img src={thumbnail} alt="" />
                    </div>
                </div>
                <div className="car_productcard_disc">
                    <div className="food_metal_electronics_disc_modelnumber">
                        <h6>#{id}</h6>
                    </div>
                    <div className="car_disc_price">
                        <h1>{price} AED</h1>
                    </div>
                    <div className="car_disc_title">
                        <h6>{title}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
