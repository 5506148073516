import React from 'react'
import '../CSS/categories.css'
import { Slide } from 'react-awesome-reveal'
import { useNavigate } from 'react-router-dom';

export default function Categories() {
    const navigate = useNavigate();
    return (
        <>
            <div className="category" id='ourcategory'>
                <div className="main">
                    <div className="title">
                        <div className="heading">
                            <h1>
                                Our Categories
                            </h1>
                        </div>
                        <div className="tags">
                            <p>
                                At Vendomes Group, we offer a comprehensive range of services to
                                streamline your international trade operations. Our expertise, global network, and
                                commitment to excellence ensure that your goods and business objectives are in
                                safe hands. Explore our suite of services designed to empower your global trade
                                endeavors:
                            </p>
                        </div>
                    </div>
                    <div className="category_dec">
                        <div className="category_part">
                            <div className="main_dec">
                                <div className="categorypart" role="button" onClick={() => navigate('/foods')}>
                                    <div className="logo">
                                        <img src="../assets/ourcategory/food.jpg" alt="Food" />
                                    </div>
                                    <div className="text">
                                        <h2>
                                            Food Products
                                        </h2>
                                    </div>
                                </div>
                                <div className="categorypart" role="button" onClick={() => navigate('/fashion')}>
                                    <div className="logo">
                                        <img src="../assets/ourcategory/fashions.jpg" alt="" />
                                    </div>
                                    <div className="text">
                                        <h2>
                                            Fashion's Cloths
                                        </h2>
                                    </div>
                                </div>

                                <div className="categorypart" role="button" onClick={() => navigate('/electronic')}>
                                    <div className="logo">
                                        <img src="../assets/ourcategory/electronics.jpg" alt="" />
                                    </div>
                                    <div className="text">
                                        <h2>
                                            Electronic's Parts
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="category_mob">
                        <div className="category_part">
                            <div className="main_dec">
                                <div className="categorypart" role="button" onClick={() => navigate('/foods')}>
                                    <div className="logo">
                                        <img src="../assets/ourcategory/food.jpg" alt="Food" />
                                    </div>
                                    <div className="text">
                                        <h2>
                                            Food Products
                                        </h2>
                                    </div>
                                </div>
                                <div className="categorypart" role="button" onClick={() => navigate('/fashion')}>
                                    <div className="logo">
                                        <img src="../assets/ourcategory/fashions.jpg" alt="" />
                                    </div>
                                    <div className="text">
                                        <h2>
                                            Fashion's Cloths
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="main_dec">
                                <div className="categorypart" role="button" onClick={() => navigate('/electronic')}>
                                    <div className="logo">
                                        <img src="../assets/ourcategory/electronics.jpg" alt="" />
                                    </div>
                                    <div className="text">
                                        <h2>
                                            Electronic's Parts
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
