export const MenuItems = [
  {
    title: 'Food Products',
    path: '../foods',
    cName: 'dropdown-link'
  },
  {
    title: 'Fashion Cloths',
    path: '../fashion',
    cName: 'dropdown-link'
  },
  {
    title: 'Electronics Parts',
    path: '../electronic',
    cName: 'dropdown-link'
  },
];
