import "../CSS/ourservies.css"
import React from 'react'

export default function OurServies() {
  return (
    <div className="ourservisesheading">
        <div className="heaaading">
            <h1>Our Servies</h1>
        </div>
    </div>
  )
}
