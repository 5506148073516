export const DataList1 = [
    {
        id: 1,
        product_id:"#VTG0010",
        title: "50-Inch Ultra HD Android Smart LED 4K TV, 4 Gigabyte+ Magic Remote Black",
        short_dis:"Contrary to popular belief, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.",
        quantity: 1,
        price: 789,
        brand: "Apple",
        was : 1899,
        discount: 60,
        saving: 1110,
        review: 75,
        star: 4.8,
        modelNo:"UHD50SLED-FL/UHD50SLED2/UHD50SLEDT1",
        seller: "Ayaan",
        sellerRating: 3.9,
        positive:80,
        thumbnail:"https://f.nooncdn.com/p/v1635349960/N16388124A_1.jpg?format=avif&width=240",
        images :[
            "https://f.nooncdn.com/p/v1633961611/N16388124A_2.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1633961610/N16388124A_3.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1633961612/N16388124A_5.jpg?format=avif&width=240",
        ]
    },
    {
        id: 2,
        product_id:"#VTG0011",
        title: "55-Inch 4K UHD Android Smart TV Black",
        short_dis:"Contrary to popular belief, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.",
        quantity: 1,
        price: 799,
        brand: "Apple",
        was : 2799,
        discount: 71,
        saving: 2000,
        review: 55,
        star: 3.5,
        modelNo:"UHD55SLED-FL/UHD5510SLED/UHD55SLED2",
        seller: "Lashra",
        sellerRating: 3.9,
        positive:80,
        thumbnail:"https://f.nooncdn.com/p/v1668492195/N16388127A_1.jpg?format=avif&width=240",
        images :[
            "https://f.nooncdn.com/p/v1668492194/N16388127A_5.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1668492195/N16388127A_4.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1668492194/N16388127A_2.jpg?format=avif&width=240",
        ]
    },
    {
        id: 3,
        product_id:"#VTG0013",
        title: "iPhone 14 Pro Max 256GB Deep Purple 5G With FaceTime - International Version",
        short_dis:"Contrary to popular belief, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.",
        quantity: 1,
        price: 4479,
        brand: "Apple",
        was : 5099,
        discount: 12,
        saving: 620,
        review: 98,
        star: 3.8,
        modelNo:"iPhone 14 Pro Max Deep Purple 256GB",
        seller: "Ayaana",
        sellerRating: 4.1,
        positive:80,
        thumbnail:"https://f.nooncdn.com/p/v1662964809/N53347167A_1.jpg?format=avif&width=240",
        images :[
            "https://f.nooncdn.com/p/v1662964809/N53347167A_2.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1662964809/N53347167A_3.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1662964809/N53347167A_2.jpg?format=avif&width=240",
        ]
    },
    {
        id: 4,
        product_id:"#VTG0014",
        title: "Macbook Air MGN63 13 Display, Apple M1 Chip With 8-Core Processory",
        short_dis:"Contrary to popular belief, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.",
        quantity: 1,
        price: 2999,
        brand: "Apple",
        was : 4199,
        discount: 28,
        saving: 1200,
        review: 98,
        star: 4.4,
        modelNo:"MGN63, MGN63B/A, MGN63HN/A",
        seller: "Ayaana",
        sellerRating: 3.9,
        positive:96,
        thumbnail:"https://f.nooncdn.com/p/v1669043087/N42284105A_1.jpg?format=avif&width=240",
        images :[
            "https://f.nooncdn.com/p/v1669043086/N42284105A_2.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1669043087/N42284105A_3.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1669043087/N42284105A_6.jpg?format=avif&width=240",
        ]
    },
   {
        id: 5,
        product_id:"#VTG0015",
        title: "50-Inch Ultra HD Android Smart LED 4K TV, 4 Gigabyte+ Magic Remote Black",
        short_dis:"Contrary to popular belief, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.",
        quantity: 1,
        price: 789,
        brand: "Apple",
        was : 1899,
        discount: 60,
        saving: 1110,
        review: 75,
        star: 4.8,
        modelNo:"UHD50SLED-FL/UHD50SLED2/UHD50SLEDT1",
        seller: "Ayaana",
        sellerRating: 3.9,
        positive:80,
        thumbnail:"https://f.nooncdn.com/p/v1635349960/N16388124A_1.jpg?format=avif&width=240",
        images :[
            "https://f.nooncdn.com/p/v1633961611/N16388124A_2.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1633961610/N16388124A_3.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1633961612/N16388124A_5.jpg?format=avif&width=240",
        ]
    },
    {
        id: 6,
        product_id:"#VTG0016",
        title: "50-Inch Ultra HD Android Smart LED 4K TV, 4 Gigabyte+ Magic Remote Black",
        short_dis:"Contrary to popular belief, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.",
        quantity: 1,
        price: 789,
        brand: "Apple",
        was : 1899,
        discount: 60,
        saving: 1110,
        review: 75,
        star: 4.8,
        modelNo:"UHD50SLED-FL/UHD50SLED2/UHD50SLEDT1",
        seller: "Ayaana",
        sellerRating: 3.9,
        positive:80,
        thumbnail:"https://f.nooncdn.com/p/v1635349960/N16388124A_1.jpg?format=avif&width=240",
        images :[
            "https://f.nooncdn.com/p/v1633961611/N16388124A_2.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1633961610/N16388124A_3.jpg?format=avif&width=240",
            "https://f.nooncdn.com/p/v1633961612/N16388124A_5.jpg?format=avif&width=240",
        ]
    },
    
   
]

export default DataList1;
