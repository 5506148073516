import "../CSS/recommened.css"
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from "react-awesome-reveal";
import { ElectronicsDevicesParts } from "../data/ElectronicsDevicesPart";
import ElectronicProductCard from "../ProductCard/ElectronicProductCard";
import { useNavigate } from "react-router-dom";


const ElectronicsDevice = () => {
    const navigate = useNavigate();
    const arrowRef = useRef(null);


    const [selectedCategory, setSelectedCategory] = useState("all");
    const [items, setItems] = useState(ElectronicsDevicesParts);


    const filterItems = (recomended) => {
        if (recomended === "all") {
            return items;
        } else {
            return items.filter((item) => item.recomended === recomended);
        }
    };

    const filteredItems = filterItems(true);
    filteredItems.sort((a, b) => a.id - b.id);
    const Data_list_1 = filteredItems.map((item, i) => (
        <ElectronicProductCard item={item} key={i} />
    ));


    return (
        <>
            <Slide direction="left">
                <div className="recomented_cover">
                    <div className="cover_img">
                        <img src="../assets/ourservice/electronics.jpg" alt="" />
                    </div>
                    <div className="cover_text">
                        <div className="cover_text_main">
                            <h1>
                                Electronics Devices & Parts
                            </h1>
                            <p>
                                Vendomes Group is a global leading distributor of electronic
                                components, with over 05 years of experience in supply chain solution services.
                                Our customers cover various vertical industries such as automotive, industrial
                                automation, telecommunications and consumer electronics. Our Main Supplier
                                in Turkey, Leveraging our vast supplier network, quality commitment, and market
                                intelligence, we provide our customers with fast, transparent, and reliable supply
                                chain services to help them cope with today's complex supply chain environment,
                                including rising procurement costs and product shortages.
                                <br />
                                <br />
                                We have established close partnerships with well-known manufacturers to
                                ensure that our customers enjoy the best prices and services when obtaining
                                high-quality chips. We will continue to adhere to our professional, efficient, and
                                high-quality service philosophy, providing our customers with top-notch chip
                                supply chain solutions and helping them achieve greater success in the market
                                competition.
                            </p>
                            <div className="more_product" role="button" onClick={() => navigate('/electronic')}>
                                <div className="more_product_border">
                                    <div className="more_product_button">
                                        <h1>More Product</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
            <div className="fashion_phones_Recommented_Scroll_main">
                <div className="food_metal_electronics_Recommented_Scroll_main">
                    {Data_list_1}
                </div>
            </div>


        </>
    )
}

export default ElectronicsDevice;