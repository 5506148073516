import "../CSS/recommened.css"
import React, { useRef, useState } from 'react'
import { Slide } from "react-awesome-reveal";
import FashionProductCard from "../ProductCard/FashionProductCard";
import fashiondata from "../data/fashion_data";
import { useNavigate } from "react-router-dom";

const FashionRecommented = () => {
    const navigate = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState("all");
    const [items, setItems] = useState(fashiondata);


    const filterItems = (recomended) => {
        if (recomended === "all") {
            return items;
        } else {
            return items.filter((item) => item.recomended === recomended);
        }
    };


    const filteredItems = filterItems(true);
    filteredItems.sort((a, b) => a.id - b.id);
    const Data_list_1 = filteredItems.map((item, i) => (
        <FashionProductCard item={item} key={i} />
    ));


    return (
        <>
            <Slide direction="left">
                <div className="recomented_cover">
                    <div className="cover_img">
                        <img src="../assets/ourservice/fashion.jpg" alt="" />
                    </div>
                    <div className="cover_text">
                        <div className="cover_text_main">
                            <h1>
                                Fashion Cloths
                            </h1>
                            <p>
                                Vendomes Group specialised in imports and exports, in the UAE and
                                world wide.basis of love for fashion and quality of clothing and apparels. Our
                                company is leading in the import and supply of modern designed clothing for
                                men, women and children products and sell reasonable price.
                                <br />
                                <br />
                                The clothing industry encompasses a vast array of products, including garments
                                for men, women, and children, as well as various types of clothing such as casual
                                wear, formal attire, sportswear, and accessories.
                                <br />
                                <br />
                                The clothing industry is truly global, with production and consumption occurring
                                in nearly every corner of the world. Different regions often specialize in the
                                production of specific types of clothing due to factors like labor costs, expertise,
                                and access to raw materials.
                            </p>
                            <div className="more_product" role="button" onClick={() => navigate('/fashion')}>
                                <div className="more_product_border">
                                    <div className="more_product_button">
                                        <h1>More Product</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
            <div className="fashion_phones_Recommented_Scroll_main">
                <div className="fashion_phones_Recommented_Scroll">
                    {Data_list_1}
                </div>
            </div>
        </>
    )
}

export default FashionRecommented;